<template>
  <!-- 合作伙伴 -->
  <div class="center">
    <div class="industry">
      <div class="industrytitle">
        <transition name="fade">
        <div v-show="trantsElemt" class="titleTagStyle">
          <div class="TitleEn">{{ projectimgitemtitleEn.toUpperCase() }}</div>
          <span class="TitleChina">{{ projectimgitemtitle }}</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
        </transition>
      </div>
    </div>
    <div class="industrytitlebox" v-if="onloading">
      <div
        class="industrytitleboxtitle action scTopone"
        :class="scTopone == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <p v-for="(item, index1) in Cooperation[index].pArr" :key="index1">
          {{ item }}
        </p>
      </div>
    </div>
    <div
      v-if="onloading"
      class="industrytitleboxbgimg action scToptwo"
      :class="scToptwo == true ? 'scroll-visible' : 'scroll-hidden'"
    >
      <img :src="Cooperation[index].URL" alt="" />
    </div>
  </div>
</template>
<script>
import { GetCooperation } from "../api/index";
import $ from 'jquery'
export default {
  props: ["cliTitle"],
  data() {
    return {
      onloading: false,
      scTopone: true,
      scToptwo: true,
      changbg: false,
      changbgimg: true,
      Cooperation: [],
      projectimgitemtitle: "合作伙伴",
      projectimgitemtitleEn: "partner",
      projectID: 0,
      index: 0,
      baseURL: this.$store.state.baseURL,
      trantsElemt:false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getCooperation();
    this.projectID = this.cliTitle.titleSub;
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
  watch: {
    cliTitle: function (newVal, oldVal) {
      this.trantsElemt = false;
      // console.log(newVal);
      this.projectID = newVal.titleSub;
      setTimeout(() => {
        this.trantsElemt = true;
      }, 50);
    },
    projectID: function (newVal, oldVal) {
      this.binding(newVal);
    },
  },
  methods: {
    async getCooperation(PageIndex = 1, PageSize = 100) {
      const res = await GetCooperation({
        PageIndex,
        PageSize,
        CooperationName: "",
      });
      if (res.code == 10000) {
        this.Cooperation = res.data.Data;
        if (this.Cooperation.length > 0) {
          this.Cooperation.forEach((item) => {
            let str = item.Body.replace(/\n/g, "!@#");
            item.pArr = str.split("!@#");
            let index = item.ImgPath.indexOf("..");
            if (index == -1) {
              item.URL = this.baseURL + item.ImgPath;
            } else {
              let arr = item.ImgPath.split("..");
              item.URL = this.baseURL + arr[1];
            }
          });
          this.projectimgitemtitle =
            this.projectimgitemtitle == "合作伙伴"
              ? this.Cooperation[0].CooperationName
              : this.projectimgitemtitle;
          this.onloading = true;
        }
      } else {
        this.$message.error(res.message);
      }
    },
    returnScorll(amount, scTop) {
      if (amount > scTop) {
        return true;
      } else {
        return false;
      }
    },
    handleScroll() {
      var clientheight = document.documentElement.clientHeight;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let amount = clientheight + scrollTop;
      if ($(".scTopone").offset()) {
        var scTopones = $(".scTopone").offset().top;
        this.scTopone = this.returnScorll(amount, scTopones);
      }
      if ($(".scToptwo").offset()) {
        var scToptwos = $(".scToptwo").offset().top;
        this.scToptwo = this.returnScorll(amount, scToptwos);
      }
    },
    binding(projectID) {
      this.getCooperation();
      setTimeout(() => {
        this.Cooperation.forEach((item, index) => {
          if (item.ID == projectID) {
            this.index = index;
            this.projectimgitemtitle = item.CooperationName;
            this.projectimgitemtitleEn = item.Remark;
          }
        });
      }, 200);
    },
  },
};
</script>
<style scoped>
.industrytitleboxbgimg img {
  width: 100%;
  height: 100%;
}
.industrytitleboxbgimg {
  margin-top: 26px;
  margin-bottom: 100px;
  width: 1200px;
  margin-left: 360px;
  height: 452px;
}
.industrytitlebox {
  width: 1820px;
  /* height: 400px; */
  margin-left: 50px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  background-color: #F9F9F9;
  /* background: url("../assets/partner/tmp53@2x.png") no-repeat; */
  background-size: 100%;
}
.industrytitleboxtitle {
  width: 1200px;
  height: 100%;
  margin: 20px 0 20px 0;
  box-sizing: border-box;
  text-indent: 34px;
  line-height: 40px;
  letter-spacing: 3px;
  text-align: left;
  font-size: 16px;
  color: #333333;
  /* font-family: SourceHanSansCN-Regular; */
}
.industrytitleboxtitle p {
  margin-bottom: 0;
}

.center {
  width: 100%;
  text-align: center;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 458px;
  height: 64px;
  line-height: 40px;
  font-size: 31px;
  margin-top: 74px;
  margin-bottom: 20px;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
.industrytitleabitiimg {
  width: 458px;
  height: 64px;
}
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
</style>