<template>
  <div class="center">
    <div class="industry">
      <div class="industrytitleabitiimg">
        <transition name="fade">
        <div v-show="trantsElemt" class="titleTagStyle">
          <div class="TitleEn">
            SCENE
          </div>
          <span class="TitleChina">{{ this.message.ScenarioName }}</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
        </transition>
      </div>
    </div>
    <div class="industrytitlebox">
      <div class="industrytitleboxfotm">
        <div class="boxfotmtitleclass">
          <!-- {{ this.Msg.Body }} -->
          <p v-for="(item,index) in pArr" :key="index">{{item}}</p>
        </div>
        <div class="industrytitleboxfotmbgimg">
          <img :src="this.Msg.ImgPathWan" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["message"],
  data() {
    return {
      pArr:[],
      Msg: {
        Body: "",
        ImgPathWan: "",
      },
      baseURL: this.$store.state.baseURL,
      trantsElemt:false
    };
  },

  mounted() {
    this.changeImgPath();
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
  methods: {
    changeImgPath() {
      this.Msg.Body = this.message.Body;
      let str = this.Msg.Body.replace(/\n/g, "!@#");
      this.pArr = str.split("!@#");
      let index2 = this.message.ImgPathWan.indexOf("..");
      if (index2 == -1) {
        this.Msg.ImgPathWan = this.baseURL + this.message.ImgPathWan;
      } else {
        let arr = this.message.ImgPathWan.split("..");
        this.Msg.ImgPathWan = this.baseURL + arr[1];
      }
    },
  },
};
</script>
<style scoped>
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
.industrytitleabitiimg {
  width: 450px;
  height: 64px;
  margin: 72px 0 42px 0;
}
.industrytitleboxfotmbgimg img {
  width: 100%;
  height: 100%;
}
.industrytitleboxfotmbgimg {
  width: 100%;
  height: 500px;
  margin: 30px 0;
}
.industrytitleboxfotm > div {
  width: 100%;
  text-align: left;
}
.boxfotmtitleclass {
  text-indent: 34px;
  line-height: 40px;
  letter-spacing: 3px;
  text-align: left;
  font-size: 16px;
  color: #333333;
  /* font-family: SourceHanSansCN-Regular; */
}
.boxfotmtitleclass p {
  margin-bottom: 0;
}
.industrytitlebox {
  width: 1200px;
  margin-left: 360px;
  font-size: 16px;
}
.center {
  width: 100%;
  text-align: center;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;
  margin: 90px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
</style>