<template>
  <div class="topitem">
    <header-banner :bannerDate="bannerDate"></header-banner>
    <div class="title">
      <div class="headtitle">
        <div class="headlogp">
          <img src="../assets/headleft2.png" alt="" />
        </div>
        <div class="headlines" @mousemove="mouseOver()">
          <span class="teshi changeStyle conter" @click="changcner('conter')"
            >首页</span
          >
          <span class="teshi changeStyle scheme" @click="changcner('scheme')"
            >产品方案</span
          >
          <span
            class="teshi changeStyle customer"
            @click="changcner('customer')"
            >案例</span
          >
          <span class="teshi changeStyle partner" @click="changcner('partner')"
            >合作伙伴</span
          >
          <span
            class="teshi changeStyle servicebefor"
            @click="changcner('servicebefor')"
            >培训与服务</span
          >
          <span class="teshi changeStyle pageone" @click="changcner('pageone')"
            >臻芯简介</span
          >
          <span class="teshu">En</span
          ><span class="teshu">{{ ConsultationHotline }}</span>
        </div>
      </div>
      <div class="headtitlebg" v-if="hover" @mouseleave="hover = false">
        <div class="headtitlebgcenter">
          <div class="headtitlebgcentertitlie">
            <div class="bgleft">
              <p
                v-for="item in ProductList"
                :key="item.ID"
                @click="changcnerSub(item, 'scheme')"
              >
                <span class="changeStyle" :class="item.ProductNameEn">{{
                  item.ProductName
                }}</span>
              </p>
            </div>
            <div class="bgright">
              <p
                @click="changcnerSub(item, 'partner')"
                v-for="item in Cooperation"
                :key="item.ID"
              >
                <span class="changeStyle" :class="item.CooperationNameEn">{{
                  item.CooperationName
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="titlebgdress" v-if="this.titile == 'addresspage'">
      <Addresspage @parentFndress="parentFndre"></Addresspage>
    </div>
    <div v-if="this.titile == 'conter'">
      <Conter @childFn="parentFn" id="conter"></Conter>
    </div>
    <div v-if="this.titile == 'scheme'">
      <Scheme @childFn="parentFn" :cliTitle="selectSub" :id="titleSub"></Scheme>
    </div>

    <div v-if="this.titile == 'service'">
      <Service id="service" :message="detailMsg"></Service>
    </div>
    <div v-if="this.titile == 'servicebefor'">
      <Servicebefor @childFn="parentFn" id="servicebefor"></Servicebefor>
    </div>
    <div v-if="this.titile == 'scenario'">
      <Scenario id="scenario"></Scenario>
    </div>
    <div v-if="this.titile == 'customer'">
      <Customer id="customer"></Customer>
    </div>
    <div v-if="this.titile == 'partner'">
      <Partner :cliTitle="selectSub" :id="titleSub"></Partner>
    </div>
    <div v-if="this.titile == 'introduction'">
      <Introduction id="introduction" :message="detailMsg"></Introduction>
    </div>
    <div v-if="this.titile == 'caseDetail'">
      <case-detail id="caseDetail" :message="detailMsg"></case-detail>
    </div>
    <div v-if="this.titile == 'buildcase'">
      <buildcase id="buildcase" :message="detailMsg"></buildcase>
    </div>
    <div v-if="this.titile == 'pageone'">
      <Pageone id="pageone"></Pageone>
    </div>
  </div>
</template>

<script>
import { GetHomeRotation, GetProduct, GetCooperation } from "../api/index";

//首页
import Conter from "@/components/conter.vue";
//产品与方案
import Scheme from "@/components/scheme.vue";
//服务保障-1
import Service from "@/components/service.vue";
//服务保障
import Servicebefor from "@/components/servicebefor.vue";
//方案名称
import Scenario from "@/components/scenario.vue";
//客户案例
import Customer from "@/components/customer.vue";
//合作伙伴
import Partner from "@/components/partner.vue";
//场景支持
import Introduction from "@/components/introduction.vue";
//首页1
import Pageone from "@/components/pageone.vue";
//地址
import Addresspage from "@/components/addresspage.vue";
import Buildcase from "./buildcase.vue";
import CaseDetail from "./caseDetail.vue";
import HeaderBanner from "./compts/headerBanner.vue";

export default {
  name: "Home",
  components: {
    Conter,
    Scheme,
    Service,
    Servicebefor,
    Scenario,
    Customer,
    Partner,
    Introduction,
    Pageone,
    Addresspage,
    Buildcase,
    CaseDetail,
    HeaderBanner,
  },
  props: ["titlechange"],

  data() {
    return {
      titile: "conter",
      titleSub: "",
      isAutoplay: true,
      bannerDate: {
        isAutoplay: true,
        bannerArr: [],
        isEmpty:false
      },
      selectSub: {},
      pageone: true,
      hover: false,
      anchorMenus: "",
      activeIndex: "1",
      activeIndex2: "1",
      ConsultationHotline: "",
      baseURL: this.$store.state.baseURL,
      ProductList: [],
      detailMsg: {},
      bannerArr: [],
      Cooperation: [],
      otherArr: [],
      prodArr: [],
      CoopArr: [],
      screenWidth: document.body.clientWidth,
    };
  },
  computed: {
    anchor() {
      return this.$store.state.menus;
    },
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    that.banner();
    that.getProduct();
    that.getCooperation();
  },
  watch: {
    anchor(newVal) {
      this.titile = newVal;
    },
    titile: {
      immediate: true,
      handler: function (newVal) {
        let documts = document.getElementsByClassName("changeStyle");
        for (var i = 0; i < documts.length; i++) {
          documts[i].classList.remove("changeColor");
        }
        if (newVal == "addresspage") {
          this.isAutoplay = false;
        } else {
          this.isAutoplay = true;
        }
        this.bannerDate = {
          isAutoplay: this.isAutoplay,
          bannerArr: this.bannerArr,
          isEmpty:false
        };
      },
    },
    "$store.state.ConsultationHotline": {
      immediate: true,
      handler: function (newVal) {
        this.ConsultationHotline = newVal;
      },
    },
    screenWidth(val) {
      this.screenWidth = val;
    },
    titlechange: "ces",
  },
  methods: {
    // 轮播
    async banner() {
      const res = await GetHomeRotation();
      if (res.code == 10000) {
        // console.log(res);
        let arr = res.data;
        arr.forEach((item) => {
          let index = item.URL.indexOf("..");
          if (index == -1) {
            item.URL = this.baseURL + item.URL;
          } else {
            let arr = item.URL.split("..");
            item.URL = this.baseURL + arr[1];
          }
        });
        this.bannerArr = arr;
        this.bannerDate = {
          isAutoplay: this.isAutoplay,
          bannerArr: this.bannerArr,
          isEmpty:this.bannerArr.length > 0 ? false : true
        };
        // console.log(this.bannerDate);
      } else {
        this.$message.err(res.message);
        this.bannerDate = {
          isAutoplay: false,
          bannerArr: [],
          isEmpty:true
        };
      }
    },
    // 产品方案
    async getProduct() {
      const res = await GetProduct({
        ProducName: "",
        PageIndex: 1,
        PageSize: 100,
      });
      if (res.code == 10000) {
        this.ProductList = [];
        let arr = res.data.Data;
        arr.forEach((item) => {
          if (item.States == 0) {
            this.ProductList.push(item);
            this.prodArr.push(item.Remark);
            this.otherArr.push(item.Remark);
            item.ProductNameEn = item.Remark;
          }
        });
      } else {
        this.$message.err(res.message);
      }
    },
    // 合作伙伴
    async getCooperation(PageIndex = 1, PageSize = 100) {
      const res = await GetCooperation({
        PageIndex,
        PageSize,
        CooperationName: "",
      });
      if (res.code == 10000) {
        // console.log(res);
        this.Cooperation = res.data.Data;
        this.Cooperation.forEach((item) => {
          this.CoopArr.push(item.Remark);
          this.otherArr.push(item.Remark);
          item.CooperationNameEn = item.Remark;
        });
        // console.log(this.Cooperation);
      } else {
        this.$message.error(res.message);
      }
    },
    mouseOver() {
      this.hover = true;
      this.showHover();
      let arr = this.otherArr;
      if (arr.includes(this.titile)) {
        let elemt = document.getElementsByClassName(this.titile)[0];
        if (elemt) {
          elemt.style.color = "#11b0de";
        }
      } else {
        arr.forEach((item) => {
          let elemts = document.getElementsByClassName(item)[0];
          if (elemts) {
            elemts.style.color = "#ffffff";
          }
        });
      }
    },
    showHover() {
      let num =
        this.ProductList.length > this.Cooperation.length
          ? this.ProductList.length
          : this.Cooperation.length;
      let headBg = document.getElementsByClassName(
        "headtitlebgcentertitlie"
      )[0];
      if (headBg) {
        let proportion = this.screenWidth / 1911;
        headBg.style.height = (num * 40 + 20)*proportion + "px";
      }

      let partner = document
        .getElementsByClassName("partner")[0]
        .getBoundingClientRect().x;
      let scheme = document
        .getElementsByClassName("scheme")[0]
        .getBoundingClientRect().x;
      let bgleft = document.getElementsByClassName("bgleft")[0];
      if (bgleft) {
        bgleft.style.left = scheme + "px";
        bgleft.style.display = "block";
      }
      let bgright = document.getElementsByClassName("bgright")[0];
      if (bgright) {
        bgright.style.left = partner + "px";
        bgright.style.display = "block";
      }
    },
    getPosition() {
      let teshu1 = document.querySelector(".teshu1");
      let QRCode = document.querySelector(".QRCode");
      QRCode.style.display = "block";
      QRCode.style.left = teshu1.offsetLeft - 30 + "px";
      QRCode.style.top = teshu1.offsetTop + 60 + "px";
    },
    ces() {
      this.titile = "addresspage";
    },
    parentFn(payload) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.titile = payload.title;
      this.detailMsg = payload.data;
    },
    parentFndre(payload) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.titile = payload;
    },
    changcner(titile) {
      this.hover = false;
      if (titile == "pageone") {
        this.pageone = false;
      } else {
        this.pageone = true;
      }
      this.titile = titile;
      if (titile == "partner") {
        this.selectSub = {
          titile: this.Cooperation[0].CooperationNameEn,
          titleSub: this.Cooperation[0].ID,
        };
        this.titleSub = this.Cooperation[0].ID;
      } else if (titile == "scheme") {
        this.selectSub = {
          titile: this.ProductList[0].ProductNameEn,
          titleSub: this.ProductList[0].ID,
        };
        this.titleSub = this.ProductList[0].ID;
      }
      this.$store.commit("setClickMenus", titile);
      setTimeout(() => {
        this.$nextTick(() => {
          if (titile == "partner" || titile == "scheme") {
            this.anchorPoint(this.titleSub);
          } else {
            this.anchorPoint(titile);
          }
        });
      }, 400);
    },
    changcnerSub(item, value) {
      this.hover = false;
      this.titile = value;
      this.titleSub = item.ID;
      this.selectSub = {
        titile: item.CooperationNameEn,
        titleSub: this.titleSub,
      };
      this.$store.commit("setClickMenus", this.titile);
      setTimeout(() => {
        this.$nextTick(() => {
          this.anchorPoint(this.titleSub);
        });
      }, 500);
    },
    anchorPoint(titile) {
      //锚点
      let anchor = document.getElementById(titile);
      if (anchor) {
        anchor.scrollIntoView();
      }
    },
  },
};
</script>

<style >
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  opacity: 0.3;
  margin: 0 20px !important;
  /* background: #11b0de; */
}
.swiper-pagination-bullet-active {
  opacity: 0.8;
  background: #fff;
}
.swiper-pagination-bullets {
  bottom: 50px !important;
}
</style>

<style lang="less" scoped >
.headtitlebgcenter {
  width: 100%;
  box-shadow: none;
}

.headtitlebgcentertitlie {
  color: #fff;
  width: 100%;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
}

.bgleft {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  text-align: left;
  min-height: 200px;
  p {
    height: 40px;
  }
}

.bgright {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  text-align: left;
  min-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
.bgleft p,
.bgright p {
  height: 40px;
  margin: 0 0 0 0 !important;
}
.bgleft p span:hover,
.bgright p span:hover {
  color: #11b0de;
}
.bgleft p:first-child,
.bgright p:first-child {
  margin-top: 20px !important;
}
.headtitlebgcentertitlie > div > p {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.headlines {
  width: 70%;
  height: 74px;
  box-shadow: none;
  text-align: left;
  display: flex;
  position: relative;
}
.headlines span {
  display: block;
  height: 78px;
  color: #fff;
  text-align: center;
  line-height: 74px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}
.teshu {
  font-size: 13px !important;
  margin-right: 15px !important;
  letter-spacing: 3px !important;
}
.teshu1 {
  margin-top: -4px;
  display: flex !important;
  align-items: center !important;
  justify-items: center !important;
  position: relative;
}
.QRCode {
  display: none;
  width: 200px;
  height: 200px;
  position: absolute;
}
.headlines .teshi {
  padding-left: 5px;
  letter-spacing: 5px;
  font-size: 16px;
  margin-right: 70px;
}
@media screen and (min-width: 1029px) {
  .headlines .teshi {
    padding-left: 5px;
    letter-spacing: 5px;
    font-size: 16px;
    margin-right: 70px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1028px) {
  .headlines .teshi {
    padding-left: 5px;
    letter-spacing: 3px;
    font-size: 14px;
    margin-right: 50px;
  }
}
@media screen and(max-width: 769px) {
  .headlines .teshi {
    padding-left: 0px;
    letter-spacing: 0px;
    font-size: 12px;
    margin-right: 20px;
  }
}
.headlines .teshi:hover {
  border-bottom: 6px solid #11b0de;
  box-sizing: border-box;
}

.el-menu-item {
  margin: 0 30px !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.el-submenu__title {
  background-color: rgba(0, 0, 0, 0) !important;
}
a {
  text-decoration: none;
}
.topitem {
  position: relative;
}
.title {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  background: rgba(255, 255, 255, 0);
}
.titlebgdress {
  position: absolute;
  top: 76px;
  z-index: 3;
  width: 100%;
  height: 624px;
  background-color: #fff;
  box-sizing: none;
}
.headtitle {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}
.headtitlebg {
  width: 100%;
  display: flex;
}
.headlogp {
  width: 440px;
}
.headlogp img {
  width: 50%;
  margin-left: 24px;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: red;
}
.changeColor {
  color: #11b0de !important;
  border-bottom: 6px solid #11b0de;
  box-sizing: border-box;
}
</style>
