<template>
  <!-- 客户案例 -->
  <div class="center">
    <div class="industry">
      <div class="industrytitle">
        <transition name="fade">
        <div v-show="trantsElemt" class="titleTagStyle">
          <div class="TitleEn">
            CUSTOMER CASE
          </div>
          <span class="TitleChina">客户案例</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
        </transition>
      </div>
    </div>
    <div class="industrytitlebox">
      <div
        class="industrytitleboxfotm action scTopone"
        :class="scTopone == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div v-for="(item, index) in Customercase" :key="item.ID">
          <div class="industrytitleName">
            {{ index + 1 + "、" + item.CustomercaseName }}
          </div>
          <div class="industrytitlBody">
            <!-- {{ item.Body }} -->
            <p v-for="(elemt, index) in item.pArr" :key="index">{{ elemt }}</p>
          </div>
          <div class="industrytitleboxfotmbar">
            <div v-for="(item1, index) in item.img" :key="index">
              <img :src="item1" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetCustomercase, GetSelectedImg } from "../api/index";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper.min.css";
import $ from 'jquery'
export default {
  data() {
    return {
      scTopone: true,
      Customercase: [],
      SelectedImg: [],
      baseURL: this.$store.state.baseURL,
      trantsElemt:false
    };
  },
  mounted() {
    this.getCustomerCase();
    this.getSelectedImg();
    window.addEventListener("scroll", this.handleScroll, true);
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
    // setTimeout(() => {
    //   this.trantsElemt = true;
    // }, 50);
  },
  methods: {
    async getCustomerCase(searchWord = "", PageIndex = 1, PageSize = 1000) {
      const res = await GetCustomercase({
        PageIndex,
        PageSize,
        CustomercaseName: searchWord,
      });
      if (res.code == 10000) {
        // console.log(res);
        this.Customercase = res.data.Data;
        this.Customercase.forEach((item) => {
          let str = item.Body.replace(/\n/g, "!@#");
          item.pArr = str.split("!@#");
          item.img = [];
          let index = item.ImgPath.indexOf("..");
          if (index == -1) {
            item.img.push(this.baseURL + item.ImgPath);
          } else {
            let arr = item.ImgPath.split("..");
            item.img.push(this.baseURL + arr[1]);
          }
        });
        if (this.Customercase.length > 0 && this.SelectedImg.length > 0) {
          this.Customercase = JSON.parse(
            JSON.stringify(
              this.addImgToArr(this.SelectedImg, this.Customercase)
            )
          );
        }
        // console.log(this.Customercase);
      } else {
        this.$message.error(res.message);
      }
    },
    async getSelectedImg(PageIndex = 1, PageSize = 1000) {
      const res = await GetSelectedImg({
        PageIndex,
        PageSize,
        SelectedImgName: "",
      });
      if (res.code == 10000) {
        // console.log(res);
        this.SelectedImg = res.data.Data;
        this.SelectedImg.forEach((item) => {
          let index = item.ImgPath.indexOf("..");
          if (index == -1) {
            item.URL = this.baseURL + item.ImgPath;
          } else {
            let arr = item.ImgPath.split("..");
            item.URL = this.baseURL + arr[1];
          }
        });
        if (this.Customercase.length > 0 && this.SelectedImg.length > 0) {
          this.Customercase = JSON.parse(
            JSON.stringify(
              this.addImgToArr(this.SelectedImg, this.Customercase)
            )
          );
        }
        // console.log(this.SelectedImg);
      } else {
        this.$message.error(res.message);
      }
    },
    addImgToArr(imgArr, arr) {
      for (let i = 0; i < imgArr.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (imgArr[i].CustomercaseID == arr[j].ID)
            arr[j].img.push(imgArr[i].URL);
        }
      }
      return arr;
    },
    handleScroll() {
      if ($(".scTopone").offset()) {
        var scTopones = $(".scTopone").offset().top;

        var clientheight = document.documentElement.clientHeight;
        var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;

        if (clientheight + scrollTop > scTopones) {
          this.scTopone = true;
        } else {
          this.scTopone = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
.industrytitleboxfotm {
  width: 1200px;
  margin-top: 40px;
  /* text-indent: 40px; */
}
.industrytitleboxfotm > div {
  margin-top: 40px;
}
.industrytitleboxfotm > div > div {
  /* padding-left: 25px; */
  /* padding-right: 15px; */
  text-align: left;
  /* font-family: SourceHanSansCN-Regular; */
}
.industrytitleName {
  font-size: 20px;
  font-weight: 600;
}
.industrytitlBody {
  text-indent: 34px;
  line-height: 40px;
  letter-spacing: 3px;
  text-align: left;
  font-size: 16px;
  color: #333333;
  /* font-family: SourceHanSansCN-Regular; */
}
.industrytitlBody p {
  text-indent: 34px;
  margin-bottom: 0;
}
.industrytitleboxfotmbar {
  width: 1200px;
  display: flex;
  flex-flow: wrap;
}
.industrytitleboxfotmbar > div {
  width: 380px;
  height: 288px;
  margin: 10px 0 0 25px;
  /* background-color: red; */
}
.industrytitleboxfotmbar > div:nth-child(3n + 1) {
  margin: 10px 0 0 0px;
}
.industrytitleboxfotmbar > div > img {
  width: 100%;
  height: 100%;
}

.industrytitlebox {
  width: 1200px;
  margin-left: 360px;
  font-size: 16px;
}
.center {
  width: 100%;
  text-align: center;
  margin-bottom: 90px;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 450px;
  height: 64px;
  margin: 72px 0 42px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
</style>
