<template>
  <div class="center">
    <div class="industry">
      <div class="industrytitle">
        <transition name="fade">
        <div v-show="trantsElemt" class="titleTagStyle">
          <div class="TitleEn">{{ projectimgitemtitleEn }}</div>
          <span class="TitleChina">{{ projectimgitemtitle }}</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
        </transition>
        <!-- <img
          class="industrytitleabiti"
          src="../assets/scheme/tmp48@2x.png"
          alt=""
        /> -->
      </div>
    </div>
    <div class="industrytitlebox">
      <div>
        <p v-for="(item,index) in pArr" :key="index">{{item}}</p>
      </div>
      <div class="industrytitleboxfotmbgimg">
          <img :src="this.message.URL1" alt="" />
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["message"],
  data() {
    return {
      pArr:[],
      trantsElemt:false,
      projectimgitemtitleEn: "SERVICE",
      projectimgitemtitle: "服务与保障",
    };
  },
  mounted(){
    this.splitBody()
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
  methods: {
    splitBody() {
      // console.log(this.message);
      this.projectimgitemtitle = this.message.TrainingName
      let str = this.message.Body.replace(/\n/g, "!@#");
      this.pArr = str.split("!@#");
    },
  },
};
</script>
<style scoped>
.industrytitleboxfotm > div {
  width: 100%;
  text-align: left;
}
.boxfotmtitleclass {
  text-indent: 40px;
  letter-spacing: 5px;
  line-height: 40px;
}
.industrytitlebox {
  width: 1200px;
  /* height: 620px; */
  margin-left: 360px;
  font-size: 16px;
}
.industrytitlebox > div {
  text-indent: 34px;
  line-height: 40px;
  letter-spacing: 3px;
  text-align: left;
  font-size: 16px;
  color: #333333;
  /* font-family: SourceHanSansCN-Regular; */
}
.industrytitlebox > div > p{
  margin-bottom: 0;
}

.industrytitleboxfotmbgimg {
  text-indent: 0px;
  width: 100%;
  height: 500px;
  margin: 30px 0;
}
.industrytitleboxfotmbgimg img {
  width: 100%;
  height: 100%;
}
.center {
  width: 100%;
  text-align: center;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;
  margin: 90px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
.industrytitleabitiimg {
  width: 458px;
  height: 64px;
}
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
</style>