<template>
  <div class="center">
    <div class="industry">
      <div class="industrytitle">
        <transition name="fade">
          <div v-show="trantsElemt" class="titleTagStyle">
            <div class="TitleEn">INTRODUCE</div>
            <span class="TitleChina">臻芯介绍</span>
            <div class="blueBorder blueBorder1"></div>
            <div class="blueBorder blueBorder2"></div>
          </div>
        </transition>
      </div>
      <div
        class="industryconter action scTopone"
        :class="scTopone == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div class="industryconterimg">
          <img
            class="industryconterqian"
            src="../assets/case/intraduce.png"
            alt=""
          />
          <img
            class="industryconterback"
            src="../assets/pageone/tmp59@2x.png"
            alt=""
          />
        </div>
        <div class="industrycontertitle">
          <div class="industrycontertitlebox">
            <div class="itleboxtit">
              {{ introduce.Introduce }}
            </div>
            <div class="itleboxtitbg"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="projectimg action scToptwo"
      :class="scToptwo == true ? 'scroll-visible' : 'scroll-hidden'"
    >
      <div class="projectimgbg"></div>
      <div
        class="projectimgitem"
        @mouseenter="playVideo"
        @mouseleave="pauseVideo"
      >
        <div class="projectimgitembg">
          <img
            v-if="fileType == 'image'"
            :src="introduce.URL"
            class="imgStyle"
            alt=""
          />
          <!-- <Video
            id="myVideo"
            v-else
            class="video-js videoStyle"
            controls
            :preload="'auto'"
          >
            <source :src="introduce.URL" type="video/mp4" />
          </Video> -->
          <video id="myVideo" v-else :src="introduce.URL" controls>
            您的浏览器不支持video标签
          </video>
          <div class="playError">视频播放出错了</div>
        </div>
        <transition name="el-zoom-in-bottom">
          <div class="transition-box">
            <div class="transitiontitle">企业文化</div>
            <div class="transitionbtncenter">enterprise culture</div>
            <div class="trafficsamlbgbox">
              <div class="trafficsamlbg"></div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="industry" v-if="CorporateImg.length > 0">
      <div
        class="industrytitlele action scTopthere"
        :class="scTopthere == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div class="titleTagStyle">
          <div class="TitleEn">PHOTO</div>
          <span class="TitleChina">精选相册</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
      </div>
      <div class="industryconterpageimg">
        <el-carousel :interval="20000" :initial-index="0" type="card">
          <el-carousel-item v-for="item in CorporateImg" :key="item.ID">
            <img class="hover" :src="item.URL" alt="" />
            <p class="imgTitle">{{ item.CorporateImgName }}</p>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import {
  GetBasicIntroduce,
  GetCorporateImg,
  GetCorporateCulture,
} from "../api/index";
import $ from "jquery";
import { getCurrentInstance } from "@vue/runtime-core";
export default {
  components: {},
  data() {
    return {
      fileType: "",
      introduce: {},
      CorporateImg: [],
      baseURL: this.$store.state.baseURL,
      img: "backgroundImage:'url('../assets/smartMine.png') '",
      projectimgitem: "1",
      projectimgitemtitle: "",
      projectimgitemtitleEn: "",
      scTopone: true,
      scToptwo: true,
      scTopthere: true,
      url: [],
      srcList: [],
      trantsElemt: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getBasicIntroduce();
    this.getCorporateCulture();
    this.getCorporateImg();
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
  methods: {
    eventVideo() {
      let video = document.getElementById("myVideo");
      let elemt = document.getElementsByClassName("transition-box")[0];
      video.addEventListener("play", function () {
        elemt.style.display = "none";
      });
      video.addEventListener("pause", function () {
        elemt.style.display = "flex";
      });
      video.addEventListener("error", function () {
        // this.$message.error("视频播放出错了");
        let playError = document.getElementsByClassName("playError")[0];
        playError.style.display = "flex";
      });
    },
    playVideo() {
      let video = document.getElementById("myVideo");
      video.play();
    },
    pauseVideo() {
      let video = document.getElementById("myVideo");
      video.pause();
    },
    async getBasicIntroduce() {
      const res = await GetBasicIntroduce();
      if (res.code == 10000) {
        // console.log(res);
        this.introduce = res.data;
        let index = this.introduce.ImgPath.indexOf("..");
        if (index == -1) {
          this.introduce.URL = this.baseURL + this.introduce.ImgPath;
        } else {
          let arr = this.introduce.ImgPath.split("..");
          this.introduce.URL = this.baseURL + arr[1];
        }
        let path = -1;
        let arr = [".mp4", ".MP4", ".wmv", ".WMV"];
        for (let i = 0; i < arr.length; i++) {
          let path1 = this.introduce.URL.indexOf(arr[i]);
          if (path1 > -1) {
            path = path1;
          }
        }
        if (path > -1) {
          this.fileType = "video";
          this.eventVideo();
        } else {
          this.fileType = "image";
        }
      } else {
        this.$message.error(res.message);
      }
    },
    async getCorporateImg() {
      const res = await GetCorporateImg({
        PageIndex: 1,
        PageSize: 100,
        CorporateImgName: "",
      });
      if (res.code == 10000) {
        // console.log(res);
        this.CorporateImg = res.data.Data;
        this.CorporateImg.forEach((item) => {
          let index = item.ImgPath.indexOf("..");
          if (index == -1) {
            item.URL = this.baseURL + item.ImgPath;
          } else {
            let arr = item.ImgPath.split("..");
            item.URL = this.baseURL + arr[1];
          }
          this.srcList.push(item.URL);
        });
        // console.log(this.CorporateImg);
      } else {
        this.$message.err(res.message);
      }
    },
    async getCorporateCulture() {
      const res = await GetCorporateCulture({
        CorporateCultureName: "",
        PageIndex: 1,
        PageSize: 100,
      });
      if (res.code == 10000) {
        // console.log(res);
      } else {
        this.$message.error(res.message);
      }
    },
    returnScorll(amount, scTop) {
      if (amount > scTop) {
        return true;
      } else {
        return false;
      }
    },
    handleScroll() {
      var clientheight = document.documentElement.clientHeight;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let amount = clientheight + scrollTop;
      if ($(".scTopone").offset()) {
        var scTopones = $(".scTopone").offset().top;
        this.scTopone = this.returnScorll(amount, scTopones);
      }
      if ($(".scToptwo").offset()) {
        var scToptwos = $(".scToptwo").offset().top;
        this.scToptwo = this.returnScorll(amount, scToptwos);
      }
    },
  },
};
</script>
<style lang='less' scoped>
::v-deep(.el-carousel__container) {
  height: 500px !important;
}
::v-deep(.el-carousel__item h3) {
  color: #475669 !important;
  font-size: 14px !important;
  opacity: 0.75 !important;
  line-height: 200px !important;
  margin: 0 !important;
}

::v-deep(.el-carousel__item:nth-child(2n)) {
  background-color: #99a9bf !important;
}

::v-deep(.el-carousel__item:nth-child(2n + 1)) {
  background-color: #d3dce6 !important;
}
::v-deep(.el-carousel__button) {
  width: 30px !important;
  height: 3px !important;
  border-radius: 0 !important;
  opacity: 0.5 !important;
  background-color: #dedede !important;
}
::v-deep(.el-carousel__indicator--horizontal) {
  padding: 10px 5px !important;
}
::v-deep(.el-carousel__indicator.is-active button) {
  opacity: 1 !important;
  background-color: #dedede !important;
}
#myVideo {
  width: 100%;
  height: 100%;
  outline: none;
  display: block;
  object-fit: fill;
}
.headlinebg {
  width: 82px;
  height: 4px;
  background-color: #11b0de;
}
.headlinep {
  font-size: 12px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  font-weight: Normal;
  margin: 5px 0;
  color: #303030;
}
.headlinetitle {
  font-size: 28px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  font-weight: Normal;
  text-align: left;
  color: #303030;
}
.headlineitemright {
  height: 100%;
  width: 80%;
}
.headlineitemright img {
  height: 100%;
  width: 100%;
}
.headlineitemleft {
  height: 100%;
  width: 15%;
  text-align: left;
}
.headlineitem {
  width: 1200px;
  margin-left: 350px;
  height: 710px;
  display: flex;
  margin-bottom: 50px;
}
.headline {
  width: 100%;
}
.casetitlehead {
  width: 100%;
  font-size: 26px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
}
.casetitlecenter {
  margin: 10px 0;
  width: 100%;
  font-size: 14px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #5b5e66;
}
.casetitleitem {
  width: 95%;
  font-size: 12px;
  text-align: left;
  color: #7d7a7a;
  letter-spacing: 1px;
  overflow: hidden;
}
.casetitle {
  width: 100%;
  display: flex;
  margin: 50px 0;
}
.casetitle div {
  flex: 1;
}

.casebox {
  position: relative;
  width: 100%;
  height: 360px;
}
.caseimg {
  width: 60%;
  height: 360px;
  background-color: #000000;
}
.caseimg img {
  width: 100%;
  height: 100%;
}

.case {
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
}
.casebg,
.casebgl {
  width: 20%;
}
.casebg img {
  width: 100%;
}
.casebgl img {
  width: 100%;
}
.projectimgitemright {
  width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.projectimgitemrighttop,
.projectimgitemrightbtn {
  width: 100%;
}
.projectimgitemrightbtn {
  height: 50%;
}

.projectimgitemrighttop img {
  width: 100%;
}
.projectimgitemrightbtn img {
  width: 100%;
}

.projectimgitemleft {
  width: 500px;
  height: 100%;
  margin-right: 4px;
}
.projectimgitemleft img {
  width: 100%;
  height: 100%;
}
.projectimgitemleftbgtitleleft {
  position: absolute;
  width: 500px;
  height: 100%;
  align-items: center;
  text-align: center;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  flex-wrap: wrap;
}

.projectimgitemleftbgtitleleftten {
  width: 100%;
  height: 50%;
}
.projectimgitemleftbgtitleleftlight {
  position: absolute;
  width: 700px;
  height: 50%;
  align-items: center;
  text-align: center;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.35);
}
.projectimgitemleftbgtitleleftlightbtn {
  position: absolute;
  width: 700px;
  height: 50%;
  align-items: center;
  text-align: center;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.35);
}
.traffictile {
  margin-top: 148px;
  width: 100%;
  font-size: 50px;
  font-weight: 700;
  color: #f9f9fb;
}
.trafficsaml {
  width: 100%;
  font-size: 14px;
  color: #f9f9fb;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 40px;
}
.trafficsamlbgbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.trafficsamlbg {
  width: 94px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.projectimgitem {
  position: absolute;
  top: 82px;
  width: 1200px;
  margin-left: 350px;
  height: 628px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.projectimgitembgg {
  position: absolute;
  top: 82px;
  width: 1200px;
  margin-left: 350px;
  height: 628px;
  background-color: #000000;
  opacity: 0.35;
  z-index: 2;
}
.projectimgitembgimg {
  width: 100%;
  height: 100%;
}
.projectimg {
  position: relative;
  width: 100%;
  height: 700px;
  margin-bottom: 50px;
}
.projectimgbg {
  width: 100%;
  height: 420px;
  background: url("../assets/smartSystem@2x.png") no-repeat;
  background-size: 100%;
}

.industrycontertitle {
  width: 48%;
  margin-left: 2%;
  height: 440px;
  font-size: 12px;
}
.industrycontertitlebox {
  width: 586px;
  height: 360px;
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
}
.itleboxtit {
  width: 528px;
  height: 316px;
  margin: 21px 0 14px 44px;
  text-indent: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
  // font-family: SourceHanSansCN-Regular;
}
.itleboxtitbg {
  width: 30px;
  height: 114px;
  position: absolute;
  background-color: #11b0de;
  top: 21px;
  left: -15px;
}
.industryconterimg {
  width: 48%;
  height: 440px;
  margin-right: 2%;
  position: relative;
}
.industryconterback {
  position: absolute;
  width: 95%;
  height: 95%;
  left: 0;
  bottom: 0;
}
.industryconterqian {
  position: absolute;
  width: 97%;
  height: 96%;
  right: 0;
  top: 0;
  z-index: 2;
}

.industrycontertitle {
  height: 419px;
  font-size: 20px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #474545;
  line-height: 50px;
  background-color: #fff;
}
.center {
  width: 100%;
  text-align: center;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 458px;
  height: 64px;
  margin: 100px 0 0 0;
  /* line-height: 40px;
  font-size: 31px;

  margin-top: 90px; */
}
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  // font-family: SourceHanSansCN-Regular;
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  // font-family: SourceHanSansCN-Regular;
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
.industrytitlele {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;

  margin: 90px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
.industrytitleanli {
  width: 60%;
  height: 100%;
}
.industryconter {
  display: flex;
  width: 100%;
  height: 620px;
  align-items: center;
}
.industryconterpageimg {
  width: 1920px;
}
.projectimgitemimg {
  width: 100%;
  height: 100%;
}
.transition-box {
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translateX(-50%);
  width: 250px;
  height: 100px;
  border-radius: 4px;

  color: #fff;
  padding: 40px 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}
.projectimgitembg {
  width: 100%;
  height: 100%;
  position: relative;
}
.playError {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  // display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.videoStyle {
  width: 100%;
  height: 100%;
  // z-index: 99;
}
.projectimgitembg img {
  width: 100%;
  height: 100%;
}
.transitiontitle {
  height: 74px;
  font-size: 50px;
  // font-family: SourceHanSansCN-Bold;
  /* line-height: 98px; */
  color: #f9f9fb;
  letter-spacing: 2px;
}
.transitionbtncenter {
  font-size: 16px;
  /* margin: 1px 0; */
  font-weight: 700;
  /* font-family: SourceHanSansCN-Bold; */
  letter-spacing: 2px;
}
.trafficsamlbgbox {
  width: 100%;
  margin-top: 45px;
  display: flex;
  justify-content: center;
}
.trafficsamlbg {
  width: 94px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.transitionbtn {
  width: 80%;
  height: 10px;
  border-radius: 8px;
  background-color: #fff;
}
.hover {
  position: relative;
  width: 100%;
  height: 100%;
}
.hoverDiv {
  position: absolute;
  width: 184px;
  height: 184px;
  z-index: 2;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
}
.hoverDiv i {
  font-size: 184px;
  color: #fff;
}
// .hover:hover {
//   opacity: 0.5;
// }
// .hover:hover .hoverDiv {
//   display: block;
// }
.imgTitle {
  position: absolute;
  left: 10px;
  bottom: 20px;
  color: #fff;
  font-size: 14px;
}
::v-deep(.vjs-error .vjs-error-display .vjs-modal-dialog-content) {
  display: none !important;
}
::v-deep(.vjs-error .vjs-error-display:before) {
  display: none !important;
}
::v-deep(.video-js .vjs-play-progress:before) {
  top: -0.7em;
}
::v-deep(.video-js .vjs-big-play-button) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
