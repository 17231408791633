import axios from 'axios'
import {postAction} from './manage'

axios.interceptors.response.use(function (response) {
    if (response.status == 200 && response.data.code == 10000) {
        return response.data;
    }
    return Promise.reject(response.data);
}, function (error) {
    return Promise.reject(error);
});

let COMMON_URL = '/'
if(!process.env.VUE_APP_BASEURL){
    console.log(COMMON_URL);
    throw new Error('Not Found API URL'); 
}else{
    let str = process.env.VUE_APP_BASEURL.substring(process.env.VUE_APP_BASEURL.length - 1)
    if(str == '/'){
        COMMON_URL = process.env.VUE_APP_BASEURL + 'ReceptionAPI/'
    }else{
        COMMON_URL = process.env.VUE_APP_BASEURL + '/ReceptionAPI/'
    }
}
//首页轮播
const GetHomeRotation = (params) => postAction(COMMON_URL+"GetHomeRotation", params)
//获取首页基本信息
const GetBasicData = (params) => postAction(COMMON_URL+"GetBasicData",params)
//首页产品图片/产品与方案/产品与方案图片
const GetProduct = (params) => postAction(COMMON_URL+"GetProduct",params)
//获取首页客户案例
const GetCustomercase = (params) => postAction(COMMON_URL+"GetCustomercase",params)
//产品与方案-建设方案
const GetConstructionScheme = (params) => postAction(COMMON_URL+"GetConstructionScheme",params)
//产品与方案-场景支持
const GetScenario = (params) => postAction(COMMON_URL+"GetScenario",params)
//产品与方案-服务保障 || 培训与服务
const GetTrainingManagement = (params) => postAction(COMMON_URL+"GetTrainingManagement",params)
//精选图片
const GetSelectedImg = (params) => postAction(COMMON_URL+"GetSelectedImg",params)
//合作伙伴
const GetCooperation = (params) => postAction(COMMON_URL+"GetCooperation",params)
//臻心简介-企业介绍
const GetBasicIntroduce = (params) => postAction(COMMON_URL+"GetBasicIntroduce",params)
//臻心简介-企业文化
const GetCorporateCulture = (params) => postAction(COMMON_URL+"GetCorporateCulture",params)
//臻心简介-企业相册
const GetCorporateImg = (params) => postAction(COMMON_URL+"GetCorporateImg",params)

export{
    GetHomeRotation,
    GetBasicData,
    GetProduct,
    GetCustomercase,
    GetConstructionScheme,
    GetScenario,
    GetTrainingManagement,
    GetSelectedImg,
    GetCooperation,
    GetBasicIntroduce,
    GetCorporateCulture,
    GetCorporateImg
}