<template>
  <div class="center">
    <div class="industry">
      <div class="industrytitle">
        <transition name="fade">
          <img
            v-show="trantsElemt"
            class="industrytitleabiti"
            src="../assets/industryTitle@2x.png"
            alt=""
          />
        </transition>
      </div>
    </div>
    <div class="industrytitlebox">
      <div class="industrytitleboxfotm" v-for="(i, index) in 3" :key="index">
        <div class="boxfotmtitle">1. 道路交通监控</div>
        <div class="boxfotmtitleclass">
          监控中心可以实时观察各节点的交通情况，在常态下，减少了交警巡逻出勤的辛劳，降低管理成本，异常情况下，可以接警后第一时间调取现场事件图像，为应急处置做充分的准备。
        </div>
        <div class="boxfotmtitleclass">
          需要高清摄像头进行支持，因为硬件将长期暴露在室外环境，加上贵州雨水气候频繁，如何降低摄像头成本，普及建设，成为道路交通监控的一大难点。
        </div>
      </div>

      <div
        class="industrytitleboxfotmbar"
        v-for="(i, index) in 2"
        :key="index"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      trantsElemt: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
};
</script>
<style scoped>
.industrytitleboxfotmbar {
  width: 1200px;
  height: 500px;
  /* background-color: red; */
  margin-top: 40px;
}
.industrytitleboxfotm > div {
  color: #333333;
  width: 100%;
  text-align: left;
}
.boxfotmtitleclass {
  text-indent: 40px;
  letter-spacing: 5px;
  line-height: 40px;
}
.industrytitlebox {
  width: 1200px;
  margin-left: 360px;
  font-size: 16px;
}
.center {
  width: 100%;
  text-align: center;
  margin-bottom: 90px;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;

  margin: 90px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
</style>
