<template>
  <!-- 产品方案 -->
  <div class="center">
    <div class="industry industrypagetitle">
      <div class="industrytitleabitiimg">
        <transition name="fade">
          <div v-show="trantsElemt" class="titleTagStyle">
            <div class="TitleEn">
              {{ projectimgitemtitleEn.toUpperCase() }}
            </div>
            <span class="TitleChina">{{ projectimgitemtitle }}</span>
            <div class="blueBorder blueBorder1"></div>
            <div class="blueBorder blueBorder2"></div>
          </div>
        </transition>
      </div>
    </div>

    <div
      class="projectimg action scToptwo"
      :class="scToptwo == true ? 'scroll-visible' : 'scroll-hidden'"
      v-if="onloading"
    >
      <div class="projectimgbg"></div>
      <two-product
        v-if="changbg && ProductList.length == 2"
        @children="children"
        :ProductList="ProductList"
      ></two-product>
      <three-product
        v-if="changbg && ProductList.length == 3"
        @children="children"
        :ProductList="ProductList"
      ></three-product>
      <four-product
        v-if="changbg && ProductList.length == 4"
        @children="children"
        :ProductList="ProductList"
      ></four-product>
      <five-product
        v-if="changbg && ProductList.length >= 5"
        @children="children"
        :ProductList="ProductList"
      ></five-product>
      <div class="projectimgitem" v-if="!changbg" @click="chang()">
        <div
          class="projectimgitembg"
          @mouseover="changeGifImg()"
          @mouseleave="changeGifImg1()"
        >
          <img v-if="isGif" :src="bindIcon(index).gif" alt="" />
          <img v-else :src="bindIcon(index).img" alt="" />
        </div>
        <transition name="el-zoom-in-bottom">
          <div v-show="changbgimg" class="transition-box">
            <div class="transitiontitle">{{ projectimgitemtitle }}</div>
            <div class="transitionbtncenter">
              {{ projectimgitemtitleEn }}
            </div>
            <div class="transitionbtn"></div>
          </div>
        </transition>
      </div>
    </div>

    <div class="industry industrypagetitle">
      <div
        class="industrytitleabitiimg action scTopthere"
        :class="scTopthere == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div class="titleTagStyle">
          <div class="TitleEn">CONSTRUCTION</div>
          <span class="TitleChina">建设方案</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
      </div>
    </div>
    <!-- <div class="industry">
      <div
        class="industrytitleabitiimg action scTopthere"
        :class="scTopthere == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <img
          class="industrytitleabitiimg"
          src="../assets/scheme/tmp47@2x.png"
          alt=""
        />
      </div>
    </div> -->
    <!-- 建设方案 -->
    <div
      class="industrytitlebox action scTopfive"
      :class="scTopfive == true ? 'scroll-visible' : 'scroll-hidden'"
    >
      <div class="industrytitleboxbg">
        <div class="bluebg">
          <div class="bluebgleft">
            <div></div>
            <div>最新</div>
          </div>
          <div class="bluebgnumber">01</div>
        </div>
      </div>
      <div class="bluebgimg">
        <swiper
          :modules="modules"
          :slides-per-view="3"
          :slidesPerGroup="1"
          ref="mySwiper"
          class="bluebgimgDiv"
          :navigation="{
            nextEl: '.next',
            prevEl: '.prev',
          }"
        >
          <swiper-slide v-for="item in ConstructionSchemeName" :key="item.ID">
            <div @click="cese('buildcase', item)" class="slideSingle">
              <div class="slideContent">
                <div class="bluebgimgtop">
                  <img :src="item.URL" alt="" />
                </div>
                <div class="bluebgimgContent">
                  <div class="bluetitle">{{ item.ConstructionSchemeName }}</div>
                  <div class="blueitem">
                    <p>{{ item.Body }}</p>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="bluebtn">
        <div class="prev">
          <img src="../assets/buttonleft.png" alt="" />
        </div>
        <div class="next">
          <img src="../assets/buttonright.png" alt="" />
        </div>
      </div>
    </div>

    <!-- <div class="schemebg industrybgimg">
      <div class="industry">
        <div class="industrytitleabitiimg industrying">
          <img
            class="industrytitleabitiimg"
            src="../assets/scheme/titilehead.png"
            alt=""
          />
        </div>
      </div> -->
    <!-- <div
        class="industrybgimgbox action scTopone"
        :class="scTopone == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div v-for="(item, index) in Scenario" :key="index">
          <div :class="item.class1">
            <div
              class="industrybgimgboxtitle"
              @click="cese('introduction', item)"
            >
              {{ item.ScenarioName }}
            </div>
            <div class="industrybgimgboxbig" :class="item.class2"></div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->

    <div class="industry industrypagetitle">
      <div
        class="industrytitleabitiimg action scTopone"
        :class="scTopone == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div class="titleTagStyle">
          <div class="TitleEn">SCENE</div>
          <span class="TitleChina">场景支持</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
      </div>
    </div>
    <div
      class="industrytitleboxleft action scTopsix"
      :class="scTopsix == true ? 'scroll-visible' : 'scroll-hidden'"
    >
      <div class="industrytitleboxbg industrytitleboxbg1">
        <div class="bluebg">
          <div class="bluebgleft">
            <div></div>
            <div>最优</div>
          </div>
          <div class="bluebgnumber">02</div>
        </div>
      </div>
      <div class="bluebgimg bluebgimgleft">
        <swiper
          :modules="modules"
          :slides-per-view="3"
          :slidesPerGroup="1"
          :navigation="{
            nextEl: '.next1',
            prevEl: '.prev1',
          }"
          ref="mySwiper"
          class="bluebgimgDiv"
        >
          <swiper-slide v-for="item in Scenario" :key="item.ID">
            <div @click="cese('introduction', item)" class="slideSingle">
              <div class="slideContent slideContent1">
                <div class="bluebgimgtop">
                  <img :src="item.URL" alt="" />
                </div>
                <div class="bluebgimgContent">
                  <div class="bluetitle">{{ item.ScenarioName }}</div>
                  <div class="blueitem">
                    <p>{{ item.Body }}</p>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="bluebtn bluebtn1">
        <div class="prev1">
          <img src="../assets/buttonleft.png" alt="" />
        </div>
        <div class="next1">
          <img src="../assets/buttonright.png" alt="" />
        </div>
      </div>
    </div>
    <div class="industry">
      <div
        class="industrytitleimgone action scTopseven"
        :class="scTopseven == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <img
          class="industrytitleabitiimg"
          src="../assets/scheme/tmp48@2x.png"
          alt=""
        />
      </div>
    </div>
    <div
      class="action scTopseight"
      :class="scTopseight == true ? 'scroll-visible' : 'scroll-hidden'"
    >
      <div
        class="industrytitleboxfotmbar"
        v-for="item in TrainingName.Data"
        :key="item.ID"
      >
        <div class="fotmbarbox">
          <div class="fotmbarleft">无忧服务保障</div>
          <div class="fotmbarcenter">
            {{ item.TrainingName }}
          </div>
          <div class="fotmbarright" @click="cese('service', item)">
            <div class="fotmbarrightcenter">详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </div>
  </div> -->
</template>
<script>
import {
  GetScenario,
  GetConstructionScheme,
  GetTrainingManagement,
  GetProduct,
} from "../api/index";
import FiveProduct from "./compts/fiveProduct.vue";
import FourProduct from "./compts/fourProduct.vue";
import threeProduct from "./compts/threeProduct.vue";
import TwoProduct from "./compts/twoProduct.vue";
import $ from "jquery";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";

export default {
  components: {
    threeProduct,
    FourProduct,
    TwoProduct,
    FiveProduct,
    Swiper,
    SwiperSlide,
  },
  props: ["cliTitle"],
  data() {
    return {
      changbg: false,
      changbgimg: true,
      onloading: false,
      isGif: false,
      ProductList: [],
      Scenario: [
        {
          class1: "industrybgimgbox1",
          class2: "industrybgimgboxbg1",
        },
        {
          class1: "industrybgimgbox2",
          class2: "industrybgimgboxbg2",
        },
        {
          class1: "industrybgimgbox3",
          class2: "industrybgimgboxbg3",
        },
        {
          class1: "industrybgimgbox4",
          class2: "industrybgimgboxbg4",
        },
        {
          class1: "industrybgimgbox5",
          class2: "industrybgimgboxbg5",
        },
        {
          class1: "industrybgimgbox6",
          class2: "industrybgimgboxbg6",
        },
        {
          class1: "industrybgimgbox7",
          class2: "industrybgimgboxbg7",
        },
        {
          class1: "industrybgimgbox8",
          class2: "industrybgimgboxbg8",
        },
      ],
      baseURL: this.$store.state.baseURL,
      ConstructionSchemeName: [],
      TrainingName: {},
      img: "backgroundImage:'url('../assets/smartMine.png') '",
      // projectimgitem: "1",
      projectimgitemtitle: "产品方案",
      projectimgitemtitleEn: "scheme",
      projectID: 0,
      index: 0,
      bluebgimg: [
        { img: require("@/assets/scheme/roundedRectangle-2@2x.png") },
        { img: require("../assets/scheme/roundedRectangle-1@2x.png") },
        { img: require("../assets/scheme/roundedRectangle6copy3@2x.png") },
      ],
      modules: [Navigation],
      scTopone: true,
      scToptwo: true,
      scTopthere: true,
      scTopfive: true,
      scTopsix: true,
      scTopseven: true,
      scTopseight: true,
      trantsElemt: false,
    };
  },
  mounted() {
    // this.projectID = this.cliTitle;
    // console.log(this.projectID);
    window.addEventListener("scroll", this.handleScroll, true);
    this.getProduct();
    // console.log(this.cliTitle);
    this.projectID = this.cliTitle.titleSub;
    this.getScenarioSupport(this.projectID);
    this.getConstruction(this.projectID);
    this.getTraining(this.projectID);
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
  watch: {
    cliTitle: function (newVal, oldVal) {
      this.trantsElemt = false;
      // console.log(newVal);
      this.projectID = newVal.titleSub;
      // console.log(this.projectID);
      this.getProduct();
      this.getScenarioSupport(this.projectID);
      this.getConstruction(this.projectID);
      this.getTraining(this.projectID);
      this.chang();
      setTimeout(() => {
        this.chang(newVal.titleSub);
        this.trantsElemt = true;
      }, 50);
      // console.log(this.projectimgitemtitleEn);
    },
    projectID: function (newVal, oldVal) {
      this.binding(newVal);
    },
    deep: true,
  },
  methods: {
    async getScenarioSupport(ID) {
      const res = await GetScenario({
        PageIndex: 1,
        PageSize: 100,
        ProductID: ID,
        ScenarioName: "",
      });
      if (res.code == 10000) {
        this.Scenario = [];
        this.Scenario = res.data.Data;
        this.Scenario.forEach((item) => {
          let index = item.ImgPathWan.indexOf("..");
          if (index == -1) {
            item.URL = this.baseURL + item.ImgPathWan;
          } else {
            let arr = item.ImgPathWan.split("..");
            item.URL = this.baseURL + arr[1];
          }
        });
      } else {
        this.$message.error(res.message);
      }
    },
    async getConstruction(ID) {
      const res = await GetConstructionScheme({
        PageIndex: 1,
        PageSize: 100,
        ProductID: ID,
        ConstructionSchemeName: "",
      });
      this.ConstructionSchemeName = [];
      if (res.code == 10000) {
        this.ConstructionSchemeName = res.data.Data;
        this.ConstructionSchemeName.forEach((item) => {
          let index = item.ImgPathWan.indexOf("..");
          if (index == -1) {
            item.URL = this.baseURL + item.ImgPathWan;
          } else {
            let arr = item.ImgPathWan.split("..");
            item.URL = this.baseURL + arr[1];
          }
        });
      } else {
        this.$message.error(res.message);
      }
    },
    async getTraining(ID) {
      const res = await GetTrainingManagement({
        PageIndex: 1,
        PageSize: 100,
        ProductID: ID,
        TrainingName: "",
      });
      if (res.code == 10000) {
        this.TrainingName = res.data;
        this.TrainingName.Data.forEach((item) => {
          let index1 = item.ImgPathWan.indexOf("..");
          if (index1 == -1) {
            item.URL1 = this.baseURL + item.ImgPathWan;
          } else {
            let arr = item.ImgPathWan.split("..");
            item.URL1 = this.baseURL + arr[1];
          }
        });
      } else {
        this.$message.error(res.message);
      }
    },
    // 产品方案
    async getProduct() {
      const res = await GetProduct({
        ProducName: "",
        PageIndex: 1,
        PageSize: 10,
      });
      if (res.code == 10000) {
        this.ProductList = [];
        let arr = res.data.Data;
        arr.forEach((item) => {
          if (item.States == 0) {
            this.ProductList.push(item);
          }
        });
        this.ProductList.forEach((item) => {
          item.ProductNameEn = item.Remark;
          let index = item.ImgPath.indexOf("..");
          if (index == -1) {
            item.ImgPath = this.baseURL + item.ImgPath;
          } else {
            let arr = item.ImgPath.split("..");
            item.ImgPath = this.baseURL + arr[1];
          }
          let index1 = item.ImgPathTO.indexOf("..");
          if (index1 == -1) {
            item.ImgPathTO = this.baseURL + item.ImgPathTO;
          } else {
            let arr = item.ImgPathTO.split("..");
            item.ImgPathTO = this.baseURL + arr[1];
          }
        });
        // console.log(this.ProductList);
        this.projectimgitemtitle =
          this.projectimgitemtitle == "产品方案"
            ? this.ProductList[0].ProductName
            : this.projectimgitemtitle;
        if (this.projectID == 0) {
          this.getScenarioSupport(this.ProductList[0].ID);
          this.getConstruction(this.ProductList[0].ID);
          this.getTraining(this.ProductList[0].ID);
        }
        this.onloading = true;
      } else {
        this.$message.err(res.message);
      }
    },
    cese(page, value) {
      let obj = {
        title: page,
        data: value,
      };
      this.$emit("childFn", obj);
    },
    binding(projectID) {
      this.getProduct();
      setTimeout(() => {
        this.ProductList.forEach((item) => {
          if (item.ID == projectID) {
            this.projectimgitemtitle = item.ProductName;
            this.projectimgitemtitleEn = item.Remark;
          }
        });
        // console.log(this.projectimgitemtitle)
      }, 200);
    },
    children(ID) {
      this.chang(ID);
    },
    chang(ID) {
      this.changbg = !this.changbg;
      // this.projectimgitem = index;
      if (ID) {
        this.ProductList.forEach((item, index) => {
          if (ID == item.ID) {
            this.index = index;
            this.projectimgitemtitle = item.ProductName;
            this.projectimgitemtitleEn = item.ProductNameEn;
          }
        });
        this.getScenarioSupport(ID);
        this.getConstruction(ID);
        this.getTraining(ID);
      }
      // setTimeout(() => {
      //   this.changbgimg = !this.changbgimg;
      // }, 200);
    },
    changeGifImg() {
      //  console.log(index + '移入');
      this.isGif = true;
      this.changbgimg = false;
    },
    changeGifImg1() {
      // console.log(index + '移出');
      this.isGif = false;
      this.changbgimg = true;
    },
    bindIcon(icon) {
      let obj = {
        img: this.ProductList[icon].ImgPath,
        gif: this.ProductList[icon].ImgPathTO,
      };
      return obj;
    },
    returnScorll(amount, scTop) {
      if (amount > scTop) {
        return true;
      } else {
        return false;
      }
    },
    handleScroll() {
      var clientheight = document.documentElement.clientHeight;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let amount = clientheight + scrollTop;
      if ($(".scTopone").offset()) {
        var scTopones = $(".scTopone").offset().top;
        this.scTopone = this.returnScorll(amount, scTopones);
      }
      if ($(".scToptwo").offset()) {
        var scToptwos = $(".scToptwo").offset().top;
        this.scToptwo = this.returnScorll(amount, scToptwos);
      }
      if ($(".scTopthere").offset()) {
        var scToptheres = $(".scTopthere").offset().top;
        this.scTopthere = this.returnScorll(amount, scToptheres);
      }
      if ($(".scTopfive").offset()) {
        var scTopfives = $(".scTopfive").offset().top;
        this.scTopfive = this.returnScorll(amount, scTopfives);
      }
      if ($(".scTopsix").offset()) {
        var scTopsixs = $(".scTopsix").offset().top;
        this.scTopsix = this.returnScorll(amount, scTopsixs);
      }
      if ($(".scTopseven").offset()) {
        var scTopsevens = $(".scTopseven").offset().top;
        this.scTopseven = this.returnScorll(amount, scTopsevens);
      }
      if ($(".scTopseight").offset()) {
        var scTopseights = $(".scTopseight").offset().top;
        this.scTopseight = this.returnScorll(amount, scTopseights);
      }
    },
  },
};
</script>
<style lang="less" scoped >
.action {
  transition: all 1s;
}
.scroll-hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
.scroll-visible {
  opacity: 1;
  transform: translateY(0);
}
.industrypagetitle {
  margin: 100px 0;
}
.industrybgimgboxbig {
  width: 94px;
  height: 94px;
  position: absolute;
}
.industrybgimgboxbg2 {
  background: url("../assets/scheme/tmp66@3x.png") no-repeat;
  background-size: 100%;
  right: 0;
  top: 0;
}
.industrybgimgboxbg4 {
  background: url("../assets/scheme/tmp60@2x.png") no-repeat;
  background-size: 100%;
  right: 0;
  top: 0;
}
.industrybgimgboxbg3 {
  background: url("../assets/scheme/tmp67@2x.png") no-repeat;
  background-size: 100%;
  right: 0;
  top: 0;
}
.industrybgimgboxbg1 {
  background: url("../assets/scheme/tmp65@2x.png") no-repeat;
  background-size: 100%;
  right: 0;
  top: 0;
}
.industrybgimgboxbg5 {
  background: url("../assets/scheme/tmp61@2x.png") no-repeat;
  background-size: 100%;
  left: 0;
  top: 0;
}
.industrybgimgboxbg6 {
  background: url("../assets/scheme/tmp62@2x.png") no-repeat;
  background-size: 100%;
  left: 0;
  top: 0;
}
.industrybgimgboxbg7 {
  background: url("../assets/scheme/tmp63@2x.png") no-repeat;
  background-size: 100%;
  left: 0;
  top: 0;
}
.industrybgimgboxbg8 {
  background: url("../assets/scheme/tmp64@2x.png") no-repeat;
  background-size: 100%;
  left: 0;
  top: 0;
}
.industrybgimgbox1 {
  left: -321px;
  top: 510px;
}
.industrybgimgbox2 {
  left: -365px;
  top: 221px;
}
.industrybgimgbox3 {
  left: -316px;
  top: -48px;
}
.industrybgimgbox4 {
  left: -112px;
  top: -296px;
}
.industrybgimgbox5 {
  left: 460px;
  top: -383px;
}
.industrybgimgbox6 {
  left: 636px;
  top: -315px;
}
.industrybgimgbox7 {
  left: 688px;
  top: -218px;
}
.industrybgimgbox8 {
  left: 632px;
  top: -128px;
}

.industrybgimgboxtitle {
  position: absolute;
  text-align: right;
  width: 234px;
  right: 130px;
  font-size: 20px;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
}
.industrybgimgboxtitleright {
  position: absolute;
  text-align: left;
  width: 234px;
  left: 130px;
  font-size: 20px;
  color: #fff;
  margin-top: 5px;
}
.industrybgimgbox > div > div {
  width: 400px;
  height: 94px;
  position: relative;
}

/* .industrybgimgbox>span{
  display: inline-block;
  width: 94px;
  height: 94px;
  background-color: red;
  position: absolute;
  background-size:100%;
} */
.industrying {
  margin-top: 10px;
}
.industrybgimgbox {
  width: 740px;
  height: 652px;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  background: url("../assets/scheme/ellipse@2x.png") no-repeat;
  background-size: 100%;
}
.industrybgimg {
  position: relative;
  background: url("../assets/scheme/tmp68@2x.png") no-repeat;
  background-size: 100%;
  overflow: hidden;
}
.industrytitleabitiimg {
  width: 458px;
  height: 64px;
}
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
.fotmbarbox {
  width: 100%;
  height: 134px;
  margin: 54px 0 0 442px;
  display: flex;
  align-content: center;
  background: url("../assets/scheme/tmp56@3x.png") no-repeat;
  background-size: 80% 100%;
}
.fotmbarleft {
  width: 310px;
  height: 134px;
  line-height: 134px;
  font-size: 26px;
  text-indent: -40px;
  color: #fff;
}
.fotmbarcenter {
  width: 462px;
  height: 134px;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  font-size: 18px !important;
  /* justify-content: end; */
  margin: 0 25px;
  text-align: left;
}
.fotmbarright {
  height: 134px;
  width: 105px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.fotmbarrightcenter {
  height: 60px;
  line-height: 60px;
  width: 80px;
  font-size: 20px;
  background-color: #c0d4f0;
  color: #fff;
}
.industrytitleboxfotmbar {
  width: 924px;
  height: 152px;
}
.industrytitleboxfotm > div {
  color: #333333;
  width: 100%;
  text-align: left;
}
.boxfotmtitleclass {
  text-indent: 40px;
  letter-spacing: 5px;
  line-height: 40px;
}
.industrytitlebox {
  width: 1200px;
  margin-left: 360px;
  font-size: 16px;
}
.center {
  width: 100%;
  text-align: center;
  margin-bottom: 90px;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;

  margin: 90px 0;
}
.industrytitleimgone {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;
  margin-bottom: 100px;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
.bluebtntwo {
  left: 0 !important;
}
.bluebgimgleft {
  left: 0 !important;
}
.bluebtn > div {
  width: 40px;
  height: 40px;
}
.bluebtn > div > img {
  width: 100%;
  height: 100%;
}
.bluebtn {
  position: absolute;
  top: 30px;
  right: 0;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.prev:focus,
.prev1:focus,
.next:focus,
.next1:focus {
  border: none !important;
  outline: none !important;
}
.bluebtn1 {
  left: 0;
}
.blueitem {
  overflow: hidden;
  height: 120px;
  font-size: 12px;
}
.blueitem p {
  padding: 0 20px 0 5px !important;
  text-align: left;
  text-indent: 34px;
  margin-bottom: 0;
  // padding: 0 10px;
  height: 100%;
  line-height: 30px;
  /* 多出的文本内容用...代替 */
  display: -webkit-box;
  display: -moz-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.blueitemboxitem {
  text-indent: 20px;
  color: #999999;
  font-size: 12px;
}
.blueitembox p {
  color: #999999;
  font-size: 12px;
}
.blueitembox {
  width: 308px;
  height: 72px;
  margin-left: 25px;
  text-align: left;
}
.bluesamll {
  text-align: left;
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #5b5e66;
}
.bluetitle {
  height: 40px;
  font-size: 24px;
  /* font-family: SourceHanSansSC-Normal; */
  line-height: 34px;
  color: #5b5e66;
  text-align: left;
  margin-left: 24px;
}
.slideSingle {
  width: 377px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.slideContent {
  width: 367px;
  height: 395px;
  box-sizing: border-box;
  box-shadow: rgba(68, 68, 68, 0.14) 0px 0px 5px;
}
.slideContent1 {
  margin-left: 9px;
}
.bluebgimgtop {
  width: 100%;
  height: 226px;
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.bluebgimgtop img {
  width: 100%;
  height: 100%;
}
.bluebgimgContent {
  height: 169px;
}
.bluebgimg > div {
  display: flex;
  flex-flow: wrap;
  // width: 346px;
  // height: 400px;
  background-color: #fff;
  // box-shadow: 0px 0px 13px rgba(127, 127, 127, 0.14);
}
.bluebgimg {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  width: 1130px;
  left: 70px;
  height: 400px;
  background-color: #fff;
}
.bluebgimgDiv {
  display: flex;
  flex-flow: wrap;
  /* width: 346px; */
  width: 100% !important;
  // height: 400px;
  background-color: #fff;
  // box-shadow: 0px 0px 13px rgba(127, 127, 127, 0.14);
}
.bluebgleft > div {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-flow: wrap;
  color: #fff;
  font-size: 18px;
}
.bluebgleft {
  display: flex;
  flex-flow: wrap;
  width: 100%;
}
.bluebgnumber {
  color: #fff;
  // width: 100%;
  height: 100%;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bluebg {
  display: flex;
  width: 120px;
  height: 64px;
  margin-left: 70px;
  margin-top: 20px;
}
.bluebg > div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
}
.industrytitleboxbg {
  width: 294px;
  height: 100%;
  background-color: #11b0de;
}
.industrytitleboxbg1 {
  position: absolute;
  right: 0;
}
.industrytitleboxleft {
  width: 1200px;
  margin-left: 360px;
  height: 512px;
  position: relative;
  margin-bottom: 84px;
  display: flex;
  justify-content: flex-end;
}

.industrytitlebox {
  width: 1200px;
  margin-left: 360px;
  height: 512px;
  position: relative;
  margin-bottom: 84px;
}
.schemebg {
  width: 100%;
  height: 844px;
}
.schemebg img {
  width: 100%;
  height: 100%;
}
.headlinebg {
  width: 82px;
  height: 4px;
  background-color: #11b0de;
}
.headlinep {
  font-size: 12px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Normal; */
  font-weight: Normal;
  margin: 5px 0;
  color: #303030;
}
.headlinetitle {
  font-size: 28px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Normal; */
  font-weight: Normal;
  text-align: left;
  color: #303030;
}
.headlineitemright {
  height: 100%;
  width: 80%;
}
.headlineitemright img {
  height: 100%;
  width: 100%;
}
.headlineitemleft {
  height: 100%;
  width: 15%;
  text-align: left;
}
.headlineitem {
  width: 1200px;
  margin-left: 350px;
  height: 710px;
  display: flex;
  margin-bottom: 50px;
}
.headline {
  width: 100%;
}
.casetitlehead {
  width: 100%;
  font-size: 26px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Regular; */
  font-weight: 400;
  text-align: left;
  color: #333333;
}
.casetitlecenter {
  margin: 10px 0;
  width: 100%;
  font-size: 14px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Regular; */
  font-weight: 400;
  text-align: left;
  color: #5b5e66;
}
.casetitleitem {
  width: 95%;
  font-size: 12px;
  text-align: left;
  color: #7d7a7a;
  letter-spacing: 1px;
  overflow: hidden;
}
.casetitle {
  width: 100%;
  display: flex;
  margin: 50px 0;
}
.casetitle div {
  flex: 1;
}

.casebox {
  position: relative;
  width: 100%;
  height: 360px;
}
.caseimg {
  width: 60%;
  height: 360px;
  background-color: #000000;
}
.caseimg img {
  width: 100%;
  height: 100%;
}

.case {
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
}
.casebg,
.casebgl {
  width: 20%;
}
.casebg img {
  width: 100%;
}
.casebgl img {
  width: 100%;
}
.projectimgitem {
  position: absolute;
  top: 82px;
  width: 1200px;
  margin-left: 350px;
  height: 628px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.projectimgitembgg {
  position: absolute;
  top: 82px;
  width: 1200px;
  margin-left: 350px;
  height: 628px;
  background-color: #000000;
  opacity: 0.35;
  z-index: 2;
}
.projectimgitembgimg {
  width: 100%;
  height: 100%;
}
.projectimg {
  position: relative;
  width: 100%;
  height: 700px;
  margin-bottom: 50px;
}
.projectimgbg {
  width: 100%;
  height: 420px;
  background: url("../assets/smartSystem@2x.png") no-repeat;
  background-size: 100%;
}

.industrycontertitle {
  width: 48%;
  margin-left: 2%;
  height: 440px;
  font-size: 12px;
}
.industryconterimg {
  width: 50%;
  height: 440px;
  position: relative;
}
.industryconterback {
  position: absolute;
  width: 95%;
  height: 95%;
  left: 0;
  bottom: 0;
}
.industryconterqian {
  position: absolute;
  width: 95%;
  height: 95%;
  right: 0;
  top: 0;
  z-index: 2;
}

.industrycontertitle {
  height: 419px;
  font-size: 20px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Regular; */
  font-weight: 400;
  text-align: left;
  color: #474545;
  line-height: 50px;
  background-color: #fff;
}
.center {
  width: 100%;
  text-align: center;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;
  margin: 100px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
.industrytitleanli {
  width: 60%;
  height: 100%;
}
.industryconter {
  display: flex;
  width: 100%;
  height: 620px;

  align-items: center;
}
.projectimgitemimg {
  width: 100%;
  height: 100%;
}
.transition-box {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
  width: 500px;
  height: 100px;
  border-radius: 4px;

  color: #fff;
  padding: 40px 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}
.projectimgitembg {
  width: 100%;
  height: 100%;
}
.projectimgitembg img {
  width: 100%;
  height: 100%;
}
.transitiontitle {
  font-size: 50px;
  letter-spacing: 2px;
}
.transitionbtncenter {
  width: 100%;
  font-size: 16px;
  /* font-family: SourceHanSansCN-Bold; */
  margin: 10px 0;
}
.transitionbtn {
  width: 100px;
  margin-top: 30px;
  height: 10px;
  border-radius: 8px;
  background-color: #fff;
}
</style>
