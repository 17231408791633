<template>
  <div class="projectimgitem">
    <div class="projectimgitemleft">
      <div class="projectimgitemrighttop">
        <img :src="ProductList[0].ImgPath" alt="" />
        <div
          class="projectimgitemleftbgtitleleftlight"
          @click="chang(ProductList[0].ID)"
        >
          <div class="traffictile">
            {{ ProductList[0].ProductName }}
          </div>
          <div class="trafficsaml">
            {{ ProductList[0].ProductNameEn }}
          </div>
          <div class="trafficsamlbgbox">
            <div class="trafficsamlbg"></div>
          </div>
        </div>
      </div>
      <div class="projectimgitemrightbtn">
        <img :src="ProductList[1].ImgPath" alt="" />
      </div>
      <div
        class="projectimgitemleftbgtitleleftlightbtn"
        @click="chang(ProductList[1].ID)"
      >
        <div class="traffictile">{{ ProductList[1].ProductName }}</div>
        <div class="trafficsaml">
          {{ ProductList[1].ProductNameEn }}
        </div>
        <div class="trafficsamlbgbox">
          <div class="trafficsamlbg"></div>
        </div>
      </div>
    </div>
    <div class="projectimgitemright">
      <div class="projectimgitemrighttop1">
        <img :src="ProductList[2].ImgPath" alt="" />
        <div
          class="projectimgitemleftbgtitleleftlight"
          @click="chang(ProductList[2].ID)"
        >
          <div class="traffictile1">
            {{ ProductList[2].ProductName }}
          </div>
          <div class="trafficsaml">
            {{ ProductList[2].ProductNameEn }}
          </div>
          <div class="trafficsamlbgbox">
            <div class="trafficsamlbg"></div>
          </div>
        </div>
      </div>
      <div class="projectimgitemrightmiddel">
        <img :src="ProductList[3].ImgPath" alt="" />
        <div
          class="projectimgitemleftbgtitleleftlight"
          @click="chang(ProductList[3].ID)"
        >
          <div class="traffictile1">
            {{ ProductList[3].ProductName }}
          </div>
          <div class="trafficsaml">
            {{ ProductList[3].ProductNameEn }}
          </div>
          <div class="trafficsamlbgbox">
            <div class="trafficsamlbg"></div>
          </div>
        </div>
      </div>
      <div class="projectimgitemrightbtn1">
        <img :src="ProductList[4].ImgPath" alt="" />
        <div
          class="projectimgitemleftbgtitleleftlight"
          @click="chang(ProductList[4].ID)"
        >
          <div class="traffictile1">
            {{ ProductList[4].ProductName }}
          </div>
          <div class="trafficsaml">
            {{ ProductList[4].ProductNameEn }}
          </div>
          <div class="trafficsamlbgbox">
            <div class="trafficsamlbg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["ProductList"],
  data() {
    return {};
  },
  methods: {
    chang(ID) {
      this.$emit("children", ID);
    },
  },
};
</script>
<style lang="less" scoped>
.projectimgitem {
  position: absolute;
  top: 82px;
  width: 1200px;
  margin-left: 350px;
  height: 628px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.projectimgitemleft {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-right: 4px;
}
// .projectimgitemleft img {
//   width: 100%;
//   height: 100%;
// }
.projectimgitemleftbgtitleleft {
  position: absolute;
  width: 600px;
  height: 100%;
  align-items: center;
  text-align: center;
  top: 0px;
  /* background-color: rgba(225, 225, 225, 0.35); */
  display: flex;
  flex-wrap: wrap;
}

.projectimgitemleftbgtitleleftten {
  width: 100%;
  height: 50%;
}
.traffictile {
  margin-top: 158px;
  width: 100%;
  font-size: 38px;
  font-weight: 500;
  color: #f9f9fb;
}
.traffictile1{
  margin-top: 78px;
  width: 100%;
  font-size: 38px;
  font-weight: 500;
  color: #f9f9fb;
}
.trafficsaml {
  width: 100%;
  font-size: 16px;
  /* font-family: SourceHanSansCN-Bold; */
  color: #f9f9fb;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 20px;
}
.trafficsamlbgbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.trafficsamlbg {
  width: 94px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.projectimgitemright {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.projectimgitemrighttop {
  width: 100%;
  height: 50%;
  position: relative;
}
.projectimgitemrightbtn {
  width: 100%;
  height: 50%;
  padding-top: 4px;
  position: relative;
}
.projectimgitemrighttop1{
  width: 100%;
  height: 33%;
  position: relative;
  padding-top: 0px;
}
.projectimgitemrightmiddel,.projectimgitemrightbtn1{
  width: 100%;
  height: 33%;
  position: relative;
  padding-top: 4px;
}
.projectimgitemrighttop img,.projectimgitemrighttop1 img ,.projectimgitemrightbtn1 img,.projectimgitemrightmiddel img{
  width: 100%;
  height: 100%;
}
.projectimgitemrightbtn img {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.projectimgitemleftbgtitleleftlight {
  position: absolute;
  width: 600px;
  height: 50%;
  align-items: center;
  text-align: center;
  top: 0%;
  /* background-color: rgba(225, 225, 225, 0.35); */
}
.projectimgitemleftbgtitleleftlightbtn {
  position: absolute;
  width: 600px;
  height: 50%;
  align-items: center;
  text-align: center;
  top: 50%;
  /* background-color: rgba(225, 225, 225, 0.35); */
}
</style>