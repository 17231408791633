<template>
  <div class="recommendPage">
    <img class="bannerBaseImg" src="../../assets/pageone/tmp58@2x.png" alt="" />
    <a-carousel autoplay>
      <div v-for="item in bannerDate.bannerArr" :key="item.ID">
        <img :src="item.URL" @click="link(item.Hyperlinks)" class="headerImg" alt="图片加载出错" />
      </div>
    </a-carousel>
  </div>
</template>
<script>
export default {
  props: ["bannerDate"],
  data() {
    return {};
  },
  mounted() {
    let bannerBaseImg = document.getElementsByClassName('bannerBaseImg')[0];
    // console.log(this.bannerDate);
    if(this.bannerDate.isEmpty){
      bannerBaseImg.style.display = 'block'
    }else{
      bannerBaseImg.style.display = 'none'
    }
  },
  methods:{
    link(links){
      // console.log(window.location.href);
      let parttner = /[a-zA-Z]+:\/\/[^\s]*/
      if(links != '' && parttner.test(links)){
        window.location.href = links
      }
      
    }
  }
};
</script>
<style lang="less" scoped>
.ant-carousel {
  height: 700px;
}
::v-deep(.ant-carousel .slick-slider .slick-track) {
  height: 700px;
}
::v-deep(.ant-carousel .slick-dots li button) {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: 1;
  background-color: #fff;
}
::v-deep(.ant-carousel .slick-dots li) {
  margin-left: 18px;
}
::v-deep(.ant-carousel .slick-dots-bottom) {
  padding: 25px 18px;
}
::v-deep(.ant-carousel .slick-dots li.slick-active button) {
  background: #11b0de;
}
.headerImg {
  width: 100%;
  height: 700px;
}
.recommendPage {
  width: 100%;
  height: 700px;
  // background: url("../../assets/pageone/tmp58@2x.png") no-repeat;
  // background-size: 100%;
}
.recommendPage > img {
  width: 100%;
  height: 100%;
  display: none;
}
</style>