<template>
  <div class="boxx">
    <div>
      <Header :titlechange="titlechanges"></Header>
    </div>
    <!-- <div>
             <Conter></Conter>
        </div> -->
    <div>
      <Buttom @paeentdress="dress"></Buttom>
    </div>
    <!-- <div class="weixinDiv">
      <div class="divStyle">
        <img src="../assets/customer/erweima.png" alt="" class="erweimaImg" />
        <div class="erweimaMsg">
          <div class="div1"></div>
          <span>扫码关注公众号</span>
          <div class="div2"></div>
        </div>
      </div>
    </div> -->
     <div class="weixinDiv1">
    </div>
    <div class="backTop" @click="backTop" title="点击回到页面顶部">
    </div>
  </div>
</template>
<script>
import Buttom from "@/components/buttom.vue";
import Header from "@/components/header.vue";
export default {
  components: { Buttom, Header },
  data() {
    return {
      titlechanges: "",
      isHover: false,
      searchBarFixed: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.scrollToTop);
  // },
  methods: {
    dress(data) {
      this.titlechanges = data;
    },
    scrollToTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let elemt = document.getElementsByClassName("backTop")[0];
      if (scrollTop > 460) {
        elemt.style.display = "flex";
      } else {
        elemt.style.display = "none";
      }
    },
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>
<style lang="less" scoped>
/* .boxx {
  position: relative;
} */
.weixinDiv1{
  position: fixed;
  width: 110px; 
  height: 120px; 
  background-image: url('../assets/customer/erweima.png');
  background-position: center top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  bottom: 80px;
  right: 30px; 
  z-index: 999999;
  // box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px 2px;
  p{
    width: 100%;
    margin: 0;
    font-size: 12px;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
}

.backTop {
  position: fixed;
  width: 48px; 
  height: 48px; 
  bottom: 15px; 
  right: 59px;
  z-index: 999999;
  cursor: pointer;
  display: none;
  background-image: url('../assets/customer/goTop.png');
  background-position: center top;
  background-size: 100% 100%;
  background-repeat: no-repeat;

}
</style>