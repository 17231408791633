<template>
  <div class="map-page-container">
    <el-amap :zoom="zoom" :center="center" @init="init">
      <el-amap-marker
        :position="position"
        @mouseover="mouseover"
        @mouseout="mouseleave"
      ></el-amap-marker>
    </el-amap>
    <el-amap-layer-traffic :visible="visible"></el-amap-layer-traffic>
    <div class="totletip">
      <span>地址:{{address}} </span><br>
      <span>热线: {{hotline}}</span>
    </div>
  </div>
</template>

<script>
import { GetBasicData } from "../api/index";
export default {
  data() {
    let self = this;
    return {
      center: [106.78769, 26.57201],
      zoom: 17,
      map: null,
      position: [106.78769, 26.57201],
      visible: true,
      address:'',
      hotline:'',
      lng: 0,
      lat: 0,
      loaded: false,
      plugin: [],
    };
  },
  mounted() {
    this.getBaseMsg();
  },
  methods: {
    init(map) {
      const marker = new AMap.Marker({
        position: this.position,
      });
      map.add(marker);
      this.map = map;
    },
    mouseover(e) {
      let totletip = document.getElementsByClassName('totletip')[0]
      totletip.style.display = 'block'
    },
    mouseleave() {
       let totletip = document.getElementsByClassName('totletip')[0]
      totletip.style.display = 'none'
    },
    async getBaseMsg() {
      const res = await GetBasicData();
      if (res.code == 10000) {
        // console.log(res);
        this.address = res.data.EnterpriseName;
        this.hotline = res.data.ConsultationHotline;
        let arr = res.data.ListAddress;
        this.center = [arr[0].X,arr[0].Y]
        this.position = [arr[0].X,arr[0].Y]
        this.plugin = [
          //一些工具插件
          {
            pName: "Geolocation", //定位
            events: {
              init(o) {
                // o 是高德地图定位插件实例

                o.getCurrentPosition((status, result) => {
                  //  console.log(result);
                  if (result && result.position) {
                    self.lng = arr[0].X;             //设置经度
                    self.lat =arr[0].Y;             //设置维度
                    // self.lng = "106.78769"; //设置经度
                    // self.lat = "26.57201"; //设置维度
                    self.center = [self.lng, self.lat]; //设置坐标
                    self.loaded = false; //load
                    self.$nextTick();
                    4; //页面渲染好后
                  }
                });
              },
            },
          },
          {
            pName: "ToolBar", //工具栏
            events: {
              init(instance) {
                // console.log(instance);
              },
            },
          },
          {
            pName: "OverView", //鹰眼
            events: {
              init(instance) {
                // console.log(instance);
              },
            },
          },
          {
            pName: "MapType", //地图类型
            defaultType: 0,
            events: {
              init(instance) {
                // console.log(instance);
              },
            },
          },
        ];
      } else {
        this.$message.err(res.message);
      }
    },
    //把经纬度传到父组件
    sendlnglat() {
      this.$emit("register", this.lng, this.lat);
    },
  },
};
</script>

<style>
.map-page-container {
  position: relative;
  width: 100%;
  height: 700px;
  font-size: 0.12rem;
  color: #fff;
}
.map-maptype-list {
  height: 60px !important;
}
.totletip {
  font-size: 16px;
  padding: 0 20px;
  line-height: 57px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 314px;
  height: 115px;
  background: rgba(7, 7, 7, 0.38);
  transform: translate(15px,-115px);
  opacity: 1;
  display: none;
}
</style>
