<template>
  <div class="footer">
    <div class="footertitle">
      <span @click="anchorPointor('conter')">首页</span>
      <span @click="anchorPointor('scheme')">产品与方案</span>
      <span @click="anchorPointor('customer')">案例</span>
      <span @click="anchorPointor('partner')">合作伙伴</span>
      <span @click="anchorPointor('servicebefor')">培训与服务</span>
      <span @click="anchorPointor('pageone')">臻芯简介</span>
    </div>
    <div class="footercenter">
      <p>公司: {{baseMassage.EnterpriseName}}</p>
      <p style="cursor: pointer;" @click="dress">公司地址: 贵阳市南明区欧美医药产业园二期五栋</p>
      <p>咨询热线: {{baseMassage.ConsultationHotline}} 邮编: {{baseMassage.ZipCode}} 邮箱: {{baseMassage.Email}}</p>
      <p>
        <span style="cursor: pointer;" @click="ipAress">黔ICP备2021009820号</span> 
        <span style="cursor: pointer;" @click="ipAress1"> <img src="../assets/police.png" alt="" class="policeImg"> 贵公网安备52010202002965号</span>
      </p>
    </div>
    <div class="footerbtn">
    </div>
  </div>
</template>
<script>
import { GetBasicData } from "../api/index";
export default {
  data() {
    return {
      url:'https://beian.miit.gov.cn/#/Integrated/recordQuery',
      url1:'http://www.beian.gov.cn/portal/registerSystemInfo',
      baseMassage:{}
    };
  },
  mounted(){
    this.getBaseMsg()
  },
  methods: {
    async getBaseMsg() {
      const res = await GetBasicData();
      if (res.code == 10000) {
        this.baseMassage = res.data;
        // console.log(this.baseMassage);
        this.$store.commit("setConsultationHotline", this.baseMassage.ConsultationHotline);
        
      } else {
        this.$message.err(res.message);
      }
    },
    dress(){
      this.$emit('paeentdress', new Date().getSeconds())
    },
    ipAress(){
      // window.location.href = this.url;
      window.open(this.url,'_blank')
    },
    ipAress1(){
      // window.location.href = this.url;
      window.open(this.url1,'_blank')
    },
    anchorPointor(title){
      this.$store.commit("setMenus", title);
      setTimeout(()=>{
        this.backTop()
      },20)
    },
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  }
};
</script>
<style scoped>
.footerbtn {
  
  height: 10px;
  text-align: center;
  border-bottom: 1px solid #fff;
  width: 100%;
  opacity: 0.8;
  font-size: 14px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Regular; */
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
}
.footercenter {
  width: 100%;
  height: 110px;
  font-size: 14px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Light; */
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  line-height: 28px;
}

.footer {
  width: 100%;
  height: 270px;
  background-color: #000;
}
.footertitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110px;
}
.footertitle span {
  /* width: 8%; */
  font-size: 16px;
  margin-left: 80px;
  /* font-family: Source Han Sans CN, Source Han Sans CN-Regular; */
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 31px;
  cursor: pointer;
}
.footertitle span:first-child{
  margin-left: 0px;
}
.policeImg{
  width: 18px;
  margin-left: 5px;
  height: 18px;
  transform: translateY(-3px);
}
</style>
