import axios from 'axios'

export function postAction(url,params){
    return axios({
        url:url,
        method:'post',
        data:params
    })
}

export function getAction(url,params){
    return axios({
        url:url,
        method:'get',
        data:params
    })
}