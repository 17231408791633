<template>
  <div class="center">
    <div class="industry">
      <div class="industrytitle">
        <transition name="fade">
        <div v-show="trantsElemt" class="titleTagStyle">
          <div class="TitleEn">{{ projectimgitemtitleEn }}</div>
          <span class="TitleChina">{{ projectimgitemtitle }}</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
        </transition>
        <!-- <img
          class="industrytitleabitiimg"
          src="../assets/scheme/tmp48@2x.png"
          alt=""
        /> -->
      </div>
    </div>
    <div class="industrytitlebox" v-if="TrainingName.length > 0">
      <div
        class="industrytitleboxfotm action scTopone"
        :class="scTopone == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div class="boxfotmtitle">
          <p v-for="(item,index) in TrainingName[0].pArr" :key="index">{{item}}</p>
        </div>
      </div>
      <div
        class="industrytitleboxfotmbar action scToptwo"
        :class="scToptwo == true ? 'scroll-visible' : 'scroll-hidden'"
        v-for="item in TrainingName"
        :key="item.ID"
      >
        <div class="fotmbarbox">
          <div class="fotmbarleft">无忧服务保障</div>
          <div class="fotmbarcenter">
            {{ item.TrainingName }}
          </div>
          <div class="fotmbarright" @click="cese(item)">
            <div class="fotmbarrightcenter">详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetTrainingManagement } from "../api/index";
import $ from 'jquery'
export default {
  data() {
    return {
      scTopone: true,
      scToptwo: true,
      TrainingName: {},
      loading: false,
      baseURL: this.$store.state.baseURL,
      projectimgitemtitleEn: "SERVICE",
      projectimgitemtitle: "服务与保障",
      trantsElemt:false
    };
  },
  mounted() {
    this.getTraining();
    window.addEventListener("scroll", this.handleScroll, true);
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
  methods: {
    async getTraining(searchWord = "", PageIndex = 1, PageSize = 1000) {
      const res = await GetTrainingManagement({
        PageIndex,
        PageSize,
        TrainingName: searchWord,
      });
      if (res.code == 10000) {
        // console.log(res);
        this.TrainingName = JSON.parse(JSON.stringify(res.data.Data));
        if (this.TrainingName.length > 0) {
          this.TrainingName.forEach((item) => {
            let str = item.Body.replace(/\n/g, "!@#");
            item.pArr = str.split("!@#");
            item.URL1 = this.addPath(item.ImgPathWan)
            item.URL2 = this.addPath(item.ImgPathTO)
          });
          this.loading = true;
        }
        // console.log(this.TrainingName);
      } else {
        this.$message.error(res.message);
      }
    },
    cese(item) {
      let obj = {
        title: "service",
        data: item,
      };
      this.$emit("childFn", obj);
    },
    addPath(path) {
      let index2 = path.indexOf("..");
      if (index2 == -1) {
        return this.baseURL + path;
      } else {
        let arr = path.split("..");
        return this.baseURL + arr[1];
      }
    },
    returnScorll(amount, scTop) {
      if (amount > scTop) {
        return true;
      } else {
        return false;
      }
    },
    handleScroll() {
      var clientheight = document.documentElement.clientHeight;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let amount = clientheight + scrollTop;
      if ($(".scTopone").offset()) {
        var scTopones = $(".scTopone").offset().top;
        this.scTopone = this.returnScorll(amount, scTopones);
      }
      if ($(".scToptwo").offset()) {
        var scToptwos = $(".scToptwo").offset().top;
        this.scToptwo = this.returnScorll(amount, scToptwos);
      }
    },
  },
};
</script>
<style scoped>
.industrytitleabitiimg {
  width: 450px;
  height: 64px;
}
.fotmbarbox {
  width: 100%;
  height: 134px;
  margin: 54px 0 0 130px;
  display: flex;
  align-content: center;
  background: url("../assets/scheme/tmp56@3x.png") no-repeat;
  background-size: 80% 100%;
}
.fotmbarleft {
  width: 310px;
  height: 134px;
  font-size: 26px;
  line-height: 134px;
  text-indent: -40px;
  color: #fff;
}
.fotmbarcenter {
  width: 462px;
  height: 134px;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  font-size: 18px !important;
  /* justify-content: end; */
  margin: 0 25px;
  text-align: left;
}
.fotmbarright {
  height: 134px;
  width: 105px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.fotmbarrightcenter {
  height: 60px;
  line-height: 60px;
  width: 80px;
  font-size: 20px;
  background-color: #c0d4f0;
  color: #fff;
}
.industrytitleboxfotmbar {
  width: 924px;
  height: 152px;
  margin-top: 80px;
}
.industrytitleboxfotm > div {
  color: #333333;
  width: 100%;
  text-indent: 34px;
  line-height: 40px;
  letter-spacing: 3px;
  text-align: left;
  font-size: 16px;
  /* font-family: SourceHanSansCN-Regular; */
}
.boxfotmtitleclass {
  text-indent: 40px;
  letter-spacing: 5px;
  line-height: 40px;
}
.boxfotmtitle p{
    margin-bottom: 0;
  }
.industrytitlebox {
  width: 1200px;
  margin-left: 360px;
  font-size: 16px;
}
.center {
  width: 100%;
  text-align: center;
  margin-bottom: 90px;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin-left: 350px;
}

.industrytitle {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;

  margin: 90px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
.industrytitleabitiimg {
  width: 458px;
  height: 64px;
}
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  /* font-family: SourceHanSansCN-Regular; */
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
</style>
