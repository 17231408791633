<template>
  <div class="center">
    <!-- 行业背景 -->
    <div class="industry">
      <div class="industrytitle">
        <transition name="fade">
          <div v-show="trantsElemt" class="titleTagStyle">
            <div class="TitleEn">INDUSTRY BACKGROUND</div>
            <span class="TitleChina">行业背景</span>
            <div class="blueBorder blueBorder1"></div>
            <div class="blueBorder blueBorder2"></div>
          </div>
        </transition>
      </div>
      <div
        class="industryconter scTopone"
        :class="scTopone == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <div class="industryconterimg">
          <img
            class="industryconterqian"
            src="../assets/case/base.png"
            alt=""
          />
        </div>
        <div class="industrycontertitle" v-if="baseMassage.BackgroundBody">
          <p v-for="(item, index) in this.baseMassage.pArr" :key="index">
            {{ item }}
          </p>
          <!-- {{ baseMassage.BackgroundBody }} -->
        </div>
      </div>
    </div>
    <!-- 产品方案 -->
    <div
      class="projectimg scToptwo"
      :class="scToptwo == true ? 'scroll-visible' : 'scroll-hidden'"
      v-if="ProductList.length != 0"
    >
      <div class="projectimgbg"></div>
      <two-product
        v-if="changbg && ProductList.length == 2"
        @children="children"
        :ProductList="ProductList"
      ></two-product>
      <three-product
        v-if="changbg && ProductList.length == 3"
        @children="children"
        :ProductList="ProductList"
      ></three-product>
      <four-product
        v-if="changbg && ProductList.length == 4"
        @children="children"
        :ProductList="ProductList"
      ></four-product>
      <five-product
        v-if="changbg && ProductList.length >= 5"
        @children="children"
        :ProductList="ProductList"
      ></five-product>
      <div class="projectimgitem" v-if="!changbg" @click="chang()">
        <div
          class="projectimgitembg"
          @mouseover="changeGifImg()"
          @mouseleave="changeGifImg1()"
        >
          <img v-if="isGif" :src="bindIcon(proindex).gif" alt="" />
          <img v-else :src="bindIcon(proindex).img" alt="" />
        </div>
        <transition name="el-zoom-in-bottom">
          <div v-show="changbgimg" class="transition-box">
            <div class="transitiontitle">{{ projectimgitemtitle }}</div>
            <div class="transitionbtncenter">
              {{ projectimgitemtitleEn }}
            </div>
            <div class="transitionbtn"></div>
          </div>
        </transition>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="industry induanli">
      <div
        class="industrytitle exp_heigh"
        :class="scTop == true ? 'scroll-visible' : 'scroll-hidden'"
      >
        <!-- <transition name="fade"> -->
        <div class="titleTagStyle">
          <div class="TitleEn">CUSTOMER CASE</div>
          <span class="TitleChina">客户案例</span>
          <div class="blueBorder blueBorder1"></div>
          <div class="blueBorder blueBorder2"></div>
        </div>
        <!-- </transition> -->
      </div>
      <div
        class="casebox scTopthere"
        :class="scTopthere == true ? 'scroll-visible' : 'scroll-hidden'"
        v-if="Customercase.length > 0"
      >
        <el-carousel :interval="3000000" type="card" @change="carouselChange">
          <el-carousel-item v-for="item in Customercase" :key="item.ID">
            <img class="hover" :src="item.ImgPath" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div
        class="casetitle action scTopfive"
        :class="scTopfive == true ? 'scroll-visible' : 'scroll-hidden'"
        v-if="Customercase.length > 0"
      >
        <div class="caseTitles caseTitles1">
          <div class="casetitlehead">
            <span>{{ Customercase1[0].CustomercaseName }}</span>
            <a-button
              type="link"
              class="seeDetail"
              @click="cese('caseDetail', Customercase1[0])"
            >
              查看详情
            </a-button>
          </div>
          <div class="casetitleitem">
            <p v-for="(elemt, index) in Customercase1[0].pArr" :key="index">
              {{ elemt }}
            </p>
          </div>
        </div>
        <div class="caseTitles caseTitles2">
          <div class="casetitlehead">
            <span>{{ Customercase1[1].CustomercaseName }}</span>
            <a-button
              type="link"
              class="seeDetail"
              @click="cese('caseDetail', Customercase1[1])"
            >
              查看详情
            </a-button>
          </div>
          <div class="casetitleitem">
            <p v-for="(elemt, index) in Customercase1[1].pArr" :key="index">
              {{ elemt }}
            </p>
          </div>
        </div>
        <div class="caseTitles caseTitles3">
          <div class="casetitlehead">
            <span>{{ Customercase1[2].CustomercaseName }}</span>
            <a-button
              type="link"
              class="seeDetail"
              @click="cese('caseDetail', Customercase1[2])"
            >
              查看详情
            </a-button>
          </div>
          <div class="casetitleitem">
            <p v-for="(elemt, index) in Customercase1[2].pArr" :key="index">
              {{ elemt }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="headline action scTopsix"
      :class="scTopsix == true ? 'scroll-visible' : 'scroll-hidden'"
    >
      <div
        class="headlineitem"
        v-for="(item, index) in introduceArr"
        :key="index"
      >
        <div class="headlineitemleft">
          <div class="headlinetitle">{{ item.china }}</div>
          <div class="headlinep">{{ item.English }}</div>
          <div class="headlinebg"></div>
        </div>
        <div class="headlineitemright">
          <img :src="item.img" />
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import { GetBasicData, GetProduct, GetCustomercase } from "../api/index";
import ThreeProduct from "./compts/threeProduct.vue";
import FourProduct from "./compts/fourProduct.vue";
import TwoProduct from "./compts/twoProduct.vue";
import FiveProduct from "./compts/fiveProduct.vue";
import $ from "jquery";
export default {
  components: {
    ThreeProduct,
    FourProduct,
    TwoProduct,
    FiveProduct,
  },
  data() {
    return {
      baseMassage: {},
      changbg: true,
      changbgimg: true,
      Customercase: [],
      Customercase1: [],
      srcList: [],
      proindex: 0,
      onloading: false,
      ProductList: [],
      isGif: false,
      baseURL: this.$store.state.baseURL,
      introduceArr: [],
      projectimgitem: "1",
      projectimgitemtitle: "",
      projectimgitemtitleEn: "",
      scTop: true,
      scTopone: true,
      scToptwo: true,
      scTopthere: true,
      scTopfive: true,
      scTopsix: true,
      trantsElemt: false,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getBaseMsg();
    this.getProduct();
    this.getCustomercase();
    setTimeout(() => {
      this.trantsElemt = true;
    }, 50);
  },
  computed: {},

  methods: {
    async getBaseMsg() {
      const res = await GetBasicData();
      if (res.code == 10000) {
        // console.log(res);
        this.baseMassage = res.data;

        let index1 = this.baseMassage.QualificationsImgPath.indexOf("..");
        if (index1 == -1) {
          this.baseMassage.QualificationsImgPath =
            this.baseURL + this.baseMassage.QualificationsImgPath;
        } else {
          let arr = this.baseMassage.QualificationsImgPath.split("..");
          this.baseMassage.QualificationsImgPath = this.baseURL + arr[1];
        }
        let index2 = this.baseMassage.TechnologyImgPath.indexOf("..");
        if (index2 == -1) {
          this.baseMassage.TechnologyImgPath =
            this.baseURL + this.baseMassage.TechnologyImgPath;
        } else {
          let arr = this.baseMassage.TechnologyImgPath.split("..");
          this.baseMassage.TechnologyImgPath = this.baseURL + arr[1];
        }
        this.introduceArr = [
          {
            china: "资质认证",
            English: "Qualification certification",
            img: this.baseMassage.QualificationsImgPath,
          },
          {
            china: "技术实力",
            English: "Technical strength",
            img: this.baseMassage.TechnologyImgPath,
          },
        ];
        // let industrycontertitle = document.getElementsByClassName('industrycontertitle')[0]
        this.baseMassage.BackgroundBody =
          this.baseMassage.BackgroundBody.replace(/\n/g, "<br/>");
        // industrycontertitle.innerHTML = this.baseMassage.BackgroundBody
        this.baseMassage.pArr = this.baseMassage.BackgroundBody.split("<br/>");
      } else {
        this.$message.err(res.message);
      }
    },
    // 案例
    async getCustomercase() {
      const res = await GetCustomercase({
        CustomercaseName: "",
        PageIndex: 1,
        PageSize: 100,
      });
      if (res.code == 10000) {
        // console.log(res);
        this.srcList = [];
        this.Customercase1 = [];
        let arr = res.data.Data;
        arr.forEach((item) => {
          let str = item.Body.replace(/\n/g, "!@#");
          item.pArr = str.split("!@#");
          let index = item.ImgPath.indexOf("..");
          if (index == -1) {
            item.ImgPath = this.baseURL + item.ImgPath;
          } else {
            let arr = item.ImgPath.split("..");
            item.ImgPath = this.baseURL + arr[1];
          }
          this.srcList.push(item.ImgPath);
          // console.log(this.srcList);
        });
        this.Customercase = JSON.parse(JSON.stringify(arr));
        this.Customercase1[0] = arr[arr.length - 1];
        this.Customercase1[1] = arr[0];
        this.Customercase1[2] = arr[1];
      } else {
        this.$message.err(res.message);
      }
    },
    // 产品方案
    async getProduct() {
      const res = await GetProduct({
        ProductName: "",
        PageIndex: 1,
        PageSize: 100,
      });
      if (res.code == 10000) {
        this.ProductList = [];
        let arr = res.data.Data;
        arr.forEach((item) => {
          if (item.States == 0) {
            this.ProductList.push(item);
          }
        });
        this.ProductList.forEach((item) => {
          if (item.States == 0) {
            item.ProductNameEn = item.Remark;
            // console.log(item.ImgPath);
            let index = item.ImgPath.indexOf("..");
            if (index == -1) {
              item.ImgPath = this.baseURL + item.ImgPath;
            } else {
              let arr = item.ImgPath.split("..");
              item.ImgPath = this.baseURL + arr[1];
            }
            let index1 = item.ImgPathTO.indexOf("..");
            if (index1 == -1) {
              item.ImgPathTO = this.baseURL + item.ImgPathTO;
            } else {
              let arr = item.ImgPathTO.split("..");
              item.ImgPathTO = this.baseURL + arr[1];
            }
          }
        });
        this.projectimgitemtitle =
          this.projectimgitemtitle == ""
            ? this.ProductList[0].ProductName
            : this.projectimgitemtitle;
        this.onloading = true;
      } else {
        this.$message.err(res.message);
      }
    },
    returnScorll(amount, scTop) {
      if (amount > scTop) {
        return true;
      } else {
        return false;
      }
    },
    carouselChange(newIndex, oldIndex) {
      let newItem = this.Customercase[newIndex];
      this.Customercase1[1] = newItem;
      if (
        (newIndex == 0 && oldIndex == newIndex + 1) ||
        (oldIndex != 0 && newIndex < oldIndex && newIndex != 0) ||
        (newIndex == this.Customercase.length - 1 && oldIndex == 0)
      ) {
        //0 1 左右  03 右左
        var indexArr = this.getDataIndexArr(
          1,
          newIndex,
          this.Customercase.length,
          false
        );
        this.Customercase1[2] = this.Customercase[indexArr[0]];
        this.Customercase1[0] = this.Customercase[indexArr[1]];
      } else {
        var indexArr = this.getDataIndexArr(
          1,
          newIndex,
          this.Customercase.length,
          true
        );
        this.Customercase1[2] = this.Customercase[indexArr[0]];
        this.Customercase1[0] = this.Customercase[indexArr[1]];
      }
    },
    getDataIndexArr(offset, currentIndex, length, isLeft) {
      let refArr = [];
      if (isLeft) {
        if (currentIndex + 1 >= length) {
          refArr.push(0);
        } else {
          refArr.push(currentIndex + 1);
        }
        if (currentIndex - 1 > -1) {
          refArr.push(currentIndex - 1);
        } else {
          refArr.push(length - 1);
        }
      } else {
        if (currentIndex + 1 >= length) {
          refArr.push(0);
        } else {
          refArr.push(currentIndex + 1);
        }
        if (currentIndex - 1 > -1) {
          refArr.push(currentIndex - 1);
        } else {
          refArr.push(length - 1);
        }
      }
      return refArr;
    },
    handleScroll() {
      var clientheight = document.documentElement.clientHeight;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let amount = clientheight + scrollTop;
      if ($(".exp_heigh").offset()) {
        var exp_height = $(".exp_heigh").offset().top;
        this.scTop = this.returnScorll(amount, exp_height);
      }
      if ($(".scTopone").offset()) {
        var scTopones = $(".scTopone").offset().top;
        this.scTopone = this.returnScorll(amount, scTopones);
      }
      if ($(".scToptwo").offset()) {
        var scToptwos = $(".scToptwo").offset().top;
        this.scToptwo = this.returnScorll(amount, scToptwos);
      }
      if ($(".scTopthere").offset()) {
        var scToptheres = $(".scTopthere").offset().top;
        this.scTopthere = this.returnScorll(amount, scToptheres);
      }
      if ($(".scTopfive").offset()) {
        var scTopfives = $(".scTopfive").offset().top;
        this.scTopfive = this.returnScorll(amount, scTopfives);
      }
      if ($(".scTopsix").offset()) {
        var scTopsixs = $(".scTopsix").offset().top;
        this.scTopsix = this.returnScorll(amount, scTopsixs);
      }
    },
    changeGifImg() {
      this.isGif = true;
      this.changbgimg = false;
    },
    changeGifImg1() {
      this.isGif = false;
      this.changbgimg = true;
    },
    cese(page, value) {
      let obj = {
        title: page,
        data: value,
      };
      this.$emit("childFn", obj);
    },
    children(ID) {
      // console.log(ID);
      this.chang(ID);
    },
    chang(ID) {
      this.changbg = !this.changbg;
      if (ID) {
        this.ProductList.forEach((item, index) => {
          if (ID == item.ID) {
            this.proindex = index;
            this.projectimgitemtitle = item.ProductName;
            this.projectimgitemtitleEn = item.ProductNameEn;
          }
        });
      }
    },
    bindIcon(icon) {
      let obj = {
        img: this.ProductList[icon].ImgPath,
        gif: this.ProductList[icon].ImgPathTO,
      };
      return obj;
    },
  },
};
</script>
<style lang='less' scoped>
::v-deep(.el-carousel__container) {
  height: 360px;
}
::v-deep(.el-carousel__arrow),
::v-deep(.el-carousel__indicators) {
  display: none !important;
}
::v-deep(.el-carousel__item) {
  height: 360px !important;
  line-height: 360px !important;
  overflow: hidden !important;
}
.hover {
  width: 100%;
  height: 360px;
}
.action {
  transition: all 1s;
}
.scTopthere {
  transition: all 1s;
}
.exp_heigh {
  transition: all 1s;
}
.scroll-hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
.scroll-visible {
  opacity: 1;
  transform: translateY(0);
}
.headlinebg {
  width: 82px;
  height: 4px;
  background-color: #11b0de;
}
.headlinep {
  font-size: 12px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  font-weight: Normal;
  margin: 5px 0;
  color: #303030;
}
.headlinetitle {
  font-size: 28px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  font-weight: Normal;
  text-align: left;
  color: #303030;
}
.headlineitemright {
  height: 100%;
  width: 82%;
}
.headlineitemright img {
  height: 100%;
  width: 100%;
}
.titleTagStyle {
  width: 100%;
  height: 100%;
  position: relative;
}
.blueBorder {
  width: 92px;
  height: 10px;
  background-color: #11b0de;
  position: absolute;
}
.blueBorder1 {
  left: 0;
  top: 45px;
}
.blueBorder2 {
  right: 0;
  top: 0px;
}
.TitleEn {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  font-size: 36px;
  // font-family: SourceHanSansCN-Regular;
  line-height: 43px;
  color: #b8b8b8;
  letter-spacing: 5px;
  opacity: 0.4;
}
.TitleChina {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-size: 31px;
  z-index: 2;
  // font-family: SourceHanSansCN-Regular;
  line-height: 38px;
  color: #333333;
  letter-spacing: 5px;
  opacity: 1;
}
.headlineitemleft {
  height: 100%;
  width: 18%;
  text-align: left;
}
.headlineitem {
  width: 1200px;
  margin-left: 350px;
  height: 710px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.headline {
  width: 100%;
  margin-top: 100px;
}
.caseTitles {
  flex: 1;
  height: 148px;
  width: 361px;
  overflow: hidden;
}
.caseTitles1 {
  padding-right: 55px;
}
.caseTitles3 {
  padding-left: 55px;
}
.seeDetail {
  display: none;
}
.caseTitles:hover .seeDetail {
  display: inline-block;
}
.casetitlehead {
  width: 100%;
  height: 28px;
  text-align: left;
  line-height: 28px;
  font-size: 22px !important;
  // font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  // text-align: left;
  color: #333333;
}
.casetitlecenter {
  margin: 10px 0;
  width: 100%;
  font-size: 14px;
  // font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #5b5e66;
}
.casetitleitem {
  width: 100%;
  font-size: 12px;
  text-align: left;
  text-indent: 26px;
  color: #7d7a7a;
  letter-spacing: 1px;
  height: 128px;
  line-height: 17px;
  // padding-right: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  /* 多出的文本内容用...代替 */
  display: -webkit-box;
  display: -moz-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}
.casetitleitem p {
  margin-bottom: 0;
}
.casetitle {
  width: 100%;
  display: flex;
  margin: 50px 0 50px 0;
  position: relative;
}

.bluebtn {
  position: absolute;
  width: 100%;
  height: 100%;
}
.bluebtn > div {
  width: 40px;
  height: 40px;
}
.bluebtn > div > img {
  width: 100%;
  height: 100%;
}
.prev3,
.next3 {
  position: absolute;
  top: 50px;
}
.next3 {
  right: 0;
}
.casebox {
  position: relative;
  width: 100%;
  height: 360px;
}
.caseimg {
  width: 60%;
  height: 360px;
  background-color: #000000;
}
.caseimg img {
  width: 100%;
  height: 100%;
}

.case {
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
}
.casebg,
.casebgl {
  width: 20%;
}
.casebg img {
  width: 100%;
}
.casebgl img {
  width: 100%;
}
.projectimgitemright {
  width: 620px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.projectimgitemrighttop,
.projectimgitemrightbtn {
  width: 100%;
  height: 50%;
}
.projectimgitemrightbtn {
  padding-top: 4px;
}

.projectimgitemrighttop img {
  width: 100%;
  height: 100%;
}
.projectimgitemrightbtn img {
  width: 100%;
  height: 100%;
}

.projectimgitemleft {
  width: 580px;
  height: 100%;
  margin-right: 4px;
}
.projectimgitemleft img {
  width: 100%;
  height: 100%;
}
.projectimgitemleftbgtitleleft {
  position: absolute;
  width: 580px;
  height: 100%;
  align-items: center;
  text-align: center;
  top: 0px;
  /* background-color: rgba(0, 0, 0, 0.35); */
  display: flex;
  flex-wrap: wrap;
}

.projectimgitemleftbgtitleleftten {
  width: 100%;
  height: 50%;
}
.projectimgitemleftbgtitleleftlight {
  position: absolute;
  width: 620px;
  height: 50%;
  align-items: center;
  text-align: center;
  top: 0%;
  /* background-color: rgba(0, 0, 0, 0.35); */
}
.projectimgitemleftbgtitleleftlightbtn {
  position: absolute;
  width: 620px;
  height: 50%;
  align-items: center;
  text-align: center;
  top: 50%;
  box-sizing: border-box;
  /* background-color: rgba(0, 0, 0, 0.35); */
}
.traffictile {
  margin-top: 128px;
  width: 100%;
  font-size: 50px;
  font-weight: 700;
  color: #f9f9fb;
}
.trafficsaml {
  width: 100%;
  font-size: 14px;
  color: #f9f9fb;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 40px;
}
.trafficsamlbgbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.trafficsamlbg {
  width: 94px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.projectimgitem {
  position: absolute;
  top: 82px;
  width: 1200px;
  margin-left: 350px;
  height: 628px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.projectimgitembgg {
  position: absolute;
  top: 82px;
  width: 1200px;
  margin-left: 350px;
  height: 628px;
  background-color: #000000;
  opacity: 0.35;
  z-index: 2;
}
.projectimgitembgimg {
  width: 100%;
  height: 100%;
}
.projectimg {
  position: relative;
  width: 100%;
  height: 700px;
  margin-bottom: 50px;
  transition: all 1s;
}
.projectimgbg {
  width: 100%;
  height: 420px;
  background: url("../assets/smartSystem@2x.png") no-repeat;
  background-size: 100%;
}
.industryconterimg {
  width: 650px;
  height: 650px;
  position: relative;
}
.industryconterback {
  width: 100%;
  height: 100%;
}
.industryconterqian {
  position: absolute;
  width: 98%;
  height: 97%;
  right: 0;
  top: 0;
  z-index: 2;
}

.industrycontertitle {
  width: 520px;
  margin-left: 2%;
  height: 500px;
  font-size: 20px;
  word-break: break-all;
  font-weight: 400;
  text-align: left;
  color: #474545;
  line-height: 40px;

  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  p {
    text-indent: 34px;
    margin-bottom: 0;
  }
}
.center {
  width: 100%;
  text-align: center;
}
.industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  // height: 735px;
  margin-left: 350px;
}

.industrytitle {
  width: 600px;
  height: 40px;
  line-height: 40px;
  font-size: 31px;

  margin: 90px 0;
}
.industrytitleabiti {
  width: 100%;
  height: 100%;
}
.industrytitleanli {
  width: 100%;
  height: 100%;
}
.industryconter {
  display: flex;
  width: 100%;
  height: 620px;

  align-items: center;
  transition: all 1s;
}
.projectimgitemimg {
  width: 100%;
  height: 100%;
}
.transition-box {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
  width: 500px;
  height: 100px;
  border-radius: 4px;

  color: #fff;
  padding: 40px 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}
.projectimgitembg {
  width: 100%;
  height: 100%;
}
.projectimgitembg img {
  width: 100%;
  height: 100%;
}
.transitiontitle {
  font-size: 50px;
  letter-spacing: 2px;
}
.transitionbtncenter {
  width: 100%;
  font-size: 14px;
  margin: 10px 0;
}
.transitionbtn {
  width: 100px;
  margin-top: 30px;
  height: 10px;
  border-radius: 8px;
  background-color: #fff;
}
::v-deep(.el-carousel__mask) {
  opacity: 0.4;
}
</style>
